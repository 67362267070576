import { Component, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from "@angular/forms";
import {
  trigger,
  state,
  animate,
  transition,
  style,
} from "@angular/animations";
import { connectionInterestService } from "src/app/service/connection.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss'],
  animations: [
    trigger("height4sAnim", [
      state("true", style({ height: "auto" })),
      state("void", style({ height: 0 })),
      transition("* => *", [animate("0.4s")]),
    ]),
    trigger("height02sAnim", [
      state("true", style({ height: 0 })),
      state("void", style({ height: 0 })),
      transition("* => *", [animate("0.4s")]),
    ]),
    trigger("smHeaderEffect", [
      state("true", style({ top: 0, opacity: 1 })),
      state("void", style({ top: 300, opacity: 0 })),
      transition("* => *", [animate("0.5s")]),
    ]),
  ],
  viewProviders: [
    { provide: ControlContainer, useExisting: FormGroupDirective },
  ],
})
export class ThankyouComponent implements OnInit {
  formSubmited: any;
  constructor(private _connectionInterestService: connectionInterestService,
    private _router: Router) { }

  ngOnInit() {

    this._connectionInterestService.formSubmited.subscribe(
      (formSubmitedValid) => (this.formSubmited = formSubmitedValid)
    );
    if (!this.formSubmited)
      this._router.navigateByUrl('')
  }


}
