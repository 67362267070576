import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import {
  trigger,
  state,
  animate,
  transition,
  style,
} from "@angular/animations";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { connectionInterestService } from "src/app/service/connection.service";
import { Subscription } from "rxjs";
import { ApiService } from "src/app/service/api.service";
import { RegisterService } from "src/app/service/register.service";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "app-agreement-acceptance",
  templateUrl: "./agreement-acceptance.component.html",
  animations: [
    trigger("height4sAnim", [
      state("true", style({ overflow: "hidden", height: "auto" })),
      state("void", style({ overflow: "hidden", height: 0 })),
      transition("* => *", [animate("0.4s")]),
    ]),
  ],
})
export class AgreementAcceptanceComponent implements OnInit {
  agreementAcceptanceFormDetails: FormGroup;
  fildShowvalue: boolean[];
  formSubmited: boolean;
  submited: boolean;

  message: any = {};
  subscription: Subscription;
  academicInfoFormValid$: any;

  private agreementAcceptence () {
    this.agreementAcceptanceFormDetails = new FormGroup({
      agreementTermsAndConditions: new FormControl("", [Validators.required]),
      agreeAutomatedTearm: new FormControl("", [Validators.required]),
    });
  }

  @Input() progressBarData: number;
  constructor (
    public TermsAndConditionsdialog: MatDialog,
    private TermsAndConditionsSnackbar: MatSnackBar,
    public _connectionInterestService: connectionInterestService,
    private registerService: RegisterService,
    public _apiService: ApiService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit () {
    // init form controls
    this.agreementAcceptence();
    this._connectionInterestService.FildShowValue.subscribe(
      (fildShowvalue) => (this.fildShowvalue = fildShowvalue)
    );
    this._connectionInterestService.formSubmited.subscribe(
      (formSubmited) => (this.formSubmited = formSubmited)
    );

    // service data
    this.registerService.getData().subscribe((data) => {
      this.resetAgreement();
    });


  }

  resetAgreement () {
    this.agreementAcceptanceFormDetails.patchValue({
      agreeAutomatedTearm: null,
      agreementTermsAndConditions: false,
    })
  }

  agreeTermsAndCondition () {
    const TermsAndConditionsdialogRef = this.TermsAndConditionsdialog.open(
      InstitutionalTermsConditionsDialog,
      { disableClose: true }
    );
    TermsAndConditionsdialogRef.afterClosed().subscribe((result) => result);
    setTimeout(
      () =>
        this.agreementAcceptanceFormDetails.controls.agreementTermsAndConditions.setValue(
          "yes"
        ),
      500
    );
  }

  // form sumbit
  agreementAcceptenceSubmit () {
    if (this.agreementAcceptanceFormDetails.valid) {
      this.submited = true
      this.registerService.getData().subscribe((data) => {
        if (data.length == 3) {
          this._apiService.submitForm(this.setSaveData(data)).subscribe(res => {
            this.submited = false
            if (!res.errors) {
              this._connectionInterestService.formSubmitedData([true]);
              this.router.navigate(['/thankyou']);
            } else {
              this.toastr.error(
                'Internal error occurred',
                'Error', {
                timeOut: 30000000,
                closeButton: true,
                positionClass: 'toast-top-right',
                toastClass: 'ngx-toastr',
              }
              );
            }
          }, (err: HttpErrorResponse) => {
            this.submited = false
            this.toastr.error('error..');
          });
        }
      });
    } else {
      this.TermsAndConditionsSnackbar.openFromComponent(
        InstitutionalTermsConditionsNotification,
        {
          duration: 2000,
        }
      );
    }
  }
  setSaveData (data) {
    let saveData = {
      first_name: data[2].firstName,
      middle_name: data[2].middleName,
      last_name: data[2].lastName,
      day_phone: data[2].phoneNumber.number,
      gender: data[2].personalDetailGender,
      date_of_birth: data[2].yourBirthDate,
      email: data[2].email,
      address: data[2].mailingAddress,
      city: data[2].personalDetailCity,
      state_id: data[2].personalDetailState,
      zipcode: data[2].personalDetailZipCode,
      country: data[2].personalDetailCountry,
      citizen: data[2].citizenship,

      location_id: data[0].formValue.degreeLocation ? data[0].formValue.degreeLocation : 2256,
      program_id: data[0].formValue.programList.id,

      level_of_education_id: data[1].level_of_education.id,
      grad_year: data[1].graduationYear,
      graduation_month: data[1].graduationMonth,
      first_name_diploma: data[1].first_name_diploma,
      last_name_diploma: data[1].last_name_diploma,
      high_school: data[1].NameOfSchool,
      standard_advanced_diploma: data[1].priorStandardCertificate,
      college_previously: data[1].finalCollegeList,
      name_college: data[1].collegeControl,
      degree_from_institution: data[1].graduateInstitution,

      lead_source_id: 3,
      tcpa_agreement: this.agreementAcceptanceFormDetails.value.agreementTermsAndConditions,
      sub_id: '',
      ip: '',
      universal_lead_id: '',
      external_lead_id: '',

      // student_number:'',
      // student_type:'',
      // start_date:'',
      // lda:'',
      // shift:'',
      // course_desc:'',
      // instructor:'',
      // absent_count:'',
      // report_url:'',
      // student_id:'',
    }
    console.log(saveData);

    return saveData
  }
  checkValidation () {
    if (this.agreementAcceptanceFormDetails.value.agreementTermsAndConditions && this.agreementAcceptanceFormDetails.value.agreeAutomatedTearm && !this.submited) {
      return true;
    }
  }
}

@Component({
  templateUrl: "institutional-terms-conditions-dialog.html",
})
export class InstitutionalTermsConditionsDialog { }

@Component({
  template:
    "<p class='text_13'>Please accept institutional agreement terms and conditions.</p>",
})
export class InstitutionalTermsConditionsNotification { }
