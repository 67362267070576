import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  API_UID = 5379;
  API_KEY = '495dd6df6c-yechmpis5o-xip7whqml8';

  constructor(private http: HttpClient) { }

  public getAcademicList() {
    return this.http.get(`https://api-remington.ynotlms.com/programs.json?uid=${this.API_UID}&apikey=${this.API_KEY}`);
  }
  public getCollegeList(query) {
    return this.http.get(`https://api.ynotlms.com/accredited-schools.json?keyword=${query}`);
  }
  public getHighSchoolList(query) {
    return this.http.get(`https://api.ynotlms.com/high-schools.json?keyword=${query}`);
  }
  public getStates(query){
    let data={
      "country":query
    }

    // return this.http.post('https://countriesnow.space/api/v0.1/countries/states',data);
    

    return this.http.get(`https://countriesnow.space/api/v0.1/countries/states/q?country=${query}`);
  }
  submitForm(data): Observable<any>{
    return this.http.post(`https://api-remington.ynotlms.com/leads-test.json`,data)
  }
}
