// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form_wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form_submited_wrp {
  border-radius: 8px;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form_submited_wrp .submited_head {
  display: flex;
  align-items: center;
}
.form_submited_wrp .submited_head .text_26 {
  font-weight: 600;
  font-size: 30px;
  line-height: 30px;
  color: #212121;
  margin-left: 16px;
}
@media only screen and (max-width: 767px) {
  .form_submited_wrp .submited_head .text_26 {
    font-size: 28px;
    line-height: 40px;
  }
}
.form_submited_wrp .submited_head img {
  max-width: 40px;
}
.form_submited_wrp .submited_step {
  margin-top: 50px;
}
.form_submited_wrp .submited_step .text_26 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #212121;
}
.form_submited_wrp .submited_step .list_sty_001 {
  margin-top: 23px;
  margin-bottom: 48px;
  padding-left: 20px;
  list-style: decimal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #212121;
  max-width: 526px;
}
.form_submited_wrp .submited_step .list_sty_001:last-child {
  margin-bottom: 0;
}
.form_submited_wrp .submited_step .list_sty_001 li {
  margin-bottom: 24px;
}
.form_submited_wrp .submited_step .list_sty_001 .contact-details {
  margin-bottom: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/thankyou/thankyou.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACF;;AACA;EACE,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;AAEF;AADE;EACE,aAAA;EACA,mBAAA;AAGJ;AAFI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,iBAAA;AAIN;AAHM;EANF;IAOI,eAAA;IACE,iBAAA;EAMR;AACF;AAJI;EACE,eAAA;AAMN;AAHE;EACE,gBAAA;AAKJ;AAJI;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAMN;AAJI;EACE,gBAAA;EACA,mBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,gBAAA;AAMN;AALM;EACE,gBAAA;AAOR;AALM;EACE,mBAAA;AAOR;AALM;EACE,mBAAA;AAOR","sourcesContent":[".form_wrapper{\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n.form_submited_wrp {\n  border-radius: 8px;\n  padding: 30px;\n  box-sizing: border-box;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  .submited_head {\n    display: flex;\n    align-items: center;\n    .text_26 {\n      font-weight: 600;\n      font-size: 30px;\n      line-height: 30px;\n      color: #212121;\n      margin-left: 16px;\n      @media only screen and (max-width: 767px) {\n        font-size: 28px;\n          line-height: 40px;\n      }\n    }\n    img {\n      max-width: 40px;\n    }\n  }\n  .submited_step {\n    margin-top: 50px;\n    .text_26 {\n      font-weight: 600;\n      font-size: 20px;\n      line-height: 24px;\n      color: #212121;\n    }\n    .list_sty_001 {\n      margin-top: 23px;\n      margin-bottom: 48px;\n      padding-left: 20px;\n      list-style: decimal;\n      font-weight: 500;\n      font-size: 15px;\n      line-height: 23px;\n      color: #212121;\n      max-width: 526px;\n      &:last-child {\n        margin-bottom: 0;\n      }\n      li {\n        margin-bottom: 24px;\n      }\n      .contact-details {\n        margin-bottom: 16px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
