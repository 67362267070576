import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";

import {
  trigger,
  state,
  animate,
  transition,
  style,
} from "@angular/animations";
import { MatSnackBar } from "@angular/material/snack-bar";
// import {
//   SearchCountryField,
//   TooltipLabel,
//   CountryISO,
// } from "ngx-intl-tel-input";
import { Subscription, Observable, ReplaySubject, Subject, of } from "rxjs";
import { debounceTime, map, startWith } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { connectionInterestService } from "src/app/service/connection.service";
import { RegisterService } from "src/app/service/register.service";
import { ApiService } from "../service/api.service";
import { ThankyouComponent } from "../thankyou/thankyou.component";
import { Country } from "../Model/CountryModel";
import { countryStore } from "src/assets/data/countries";
import { MatSelect } from "@angular/material/select";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";

interface CountryModel {
  iso: string;
  name: string;
  flag: string;
  number: string;
}
export interface Suffix {
  value: string;
  viewValue: string;
}

@Component({
  selector: "app-personal-information",
  templateUrl: "./personal-information.component.html",
  styleUrls: ["./personal-information.component.scss"],
  animations: [
    trigger("height4sAnim", [
      state("true", style({ overflow: "hidden", height: "auto" })),
      state("void", style({ overflow: "hidden", height: 0 })),
      transition("* => *", [animate("0.4s")]),
    ]),
    trigger("height1sAnim", [
      state("true", style({ overflow: "hidden", height: "auto" })),
      state("void", style({ overflow: "hidden", height: 0 })),
      transition("* => *", [animate("1s")]),
    ]),
  ],
})
export class PersonalInformationComponent implements OnInit {
  subscription: Subscription;
  personalData: any;
  separateDialCode = true;
  // SearchCountryField = SearchCountryField;
  // TooltipLabel = TooltipLabel;
  // CountryISO = CountryISO;
  // selectedCountry:CountryISO=CountryISO.UnitedStates;
  // preferredCountries: CountryISO[] = [
  //   CountryISO.UnitedStates,
  //   CountryISO.UnitedKingdom,
  // ]; 
  personalDetailFormShow: boolean;
  personalEditData: any = null;
  personalSortDataShow: boolean;
  personalFormDetails: UntypedFormGroup;
  filteredCountry: any;
  filteredCitzenship :any; 
  nonUSCitizenship: any;
  fildShowvalue: any;
  selectedValue: any;
  selectedValue1: any;
  messageFildShowValue: any;
  personalPhoneNumber: any;
  totalValidField: number = 0;
  firstNameStatus: boolean;
  validateCheckDisable=true;
  states:any=[];
  tempArrayStates:any=[];
  stateTerm='';

  public countryCtrl: UntypedFormControl = new UntypedFormControl();
  public countryFilterCtrl: UntypedFormControl = new UntypedFormControl();

  // public filteredCountryModels: ReplaySubject<CountryModel[]> =
  //   new ReplaySubject<CountryModel[]>(1);

  public citizenshipCtrl: UntypedFormControl = new UntypedFormControl();
  public citizenshipFilterCtrl: UntypedFormControl = new UntypedFormControl();
  // public filteredCitizenshipModels: ReplaySubject<CountryModel[]> =
  //   new ReplaySubject<CountryModel[]>(1);

  private _onDestroy = new Subject<void>();

  public progress: number;
  public formObserver: Subscription;
  countryinit:any;
  date = new Date();
  dobLimit =new Date(this.date.getFullYear() - 14,this.date.getMonth(),this.date.getDay());
  @Output() progressBarData = new EventEmitter<any>();
  @ViewChild("singleSelect", { read: true, static: false })
  singleSelect: MatSelect;
  public validation_msgs = {
    'stateControl': [
      { type: 'required', message: 'Please enter your State. ' }
    ],
  }
  private countrynamelist:Country[] = countryStore.country

  public stateControl=new UntypedFormControl("");
  ngOnInit() {
    // this.personalFormDetails.get('personalDetailCountry').setValue("United States Of America");
    this.stateControl.valueChanges.subscribe(data=>{
      this.personalFormDetails.get('personalDetailState').setValue(data);
    })
    this.personalFormDetails = new UntypedFormGroup({
      firstName: new UntypedFormControl(""),
      phoneNumber: new UntypedFormControl([], [Validators.required]),
      middleName: new UntypedFormControl(""),
      lastName: new UntypedFormControl("", [Validators.required]),
      countryCode:new UntypedFormControl([], [Validators.required]),
      email: new UntypedFormControl("", [Validators.required,Validators.pattern(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),Validators.email]),
      suffix: new UntypedFormControl("",),
      // lastNameChanged: new UntypedFormControl(""),
      // previousLastName: new UntypedFormControl(""),
      currentlyResideInUSA: new UntypedFormControl("no"),
      mailingAddress: new UntypedFormControl(""),
      personalDetailCity: new UntypedFormControl(""),
      personalDetailState: new UntypedFormControl("",Validators.required),
      personalDetailZipCode: new UntypedFormControl(""),
      personalDetailGender: new UntypedFormControl("male"),
      personalDetailCountry: new UntypedFormControl("United States of America"),
      yourBirthDate: new UntypedFormControl(""),
      // racesAndEthnicities: new UntypedFormControl(""),
      USCitizen: new UntypedFormControl(""),
      // socialSecurityNumber: new UntypedFormControl(""),
      // federalFinancialAid: new UntypedFormControl(""),
      greenCardHolder: new UntypedFormControl(""),
      servedUSArmedForces: new UntypedFormControl(""),
      militaryStatusControl: new UntypedFormControl(""),
      militaryBranchControl: new UntypedFormControl(""),
      MilitaryPayGrade: new UntypedFormControl(""),
      militaryTuitionAssistance: new UntypedFormControl(""),
      licensesCertifications: new UntypedFormControl(""),
      militaryEducationAssistance: new UntypedFormControl(""),
      collegCreditAwarded: new UntypedFormControl(""),
      licenseCertificationName: new UntypedFormControl(""),
      citizenship: new UntypedFormControl(""),
    });

    this.countryinit="CountryISO.UnitedStates";
    // this.apiService.getStates('United States').subscribe((data:any)=>{
    //   for(var i=0;i<data.data.states.length;i++){
    //     this.states.push(data.data.states[i]);

    //   }
    // })
      this.countrySelected=true;
    this.states = countryStore.state.find(m=> m.country == 'United States').states
    this.tempArrayStates=this.states;

    // this.filteredCountryModels.next(this.countrynamelist.slice());
    this.personalFormDetails.get('personalDetailCountry').setValue("United States");
    // this.personalFormDetails.get('personalDetailCountry').valueChanges
    //   .pipe(takeUntil(this._onDestroy))
    //   .subscribe((data) => {
    //     // this.filterCountryModels();
    //     this.personalFormDetails.get('personalDetailCountry').setValue(data);
    //     this.personalFormDetails.get('personalDetailState').setValue(null);
    //     this.stateControl.setValue(null);
    //   });

    // this.citizenshipCtrl.setValue(this.countrynamelist[0]);
    // this.filteredCitizenshipModels.next(this.countrynamelist.slice());
    // this.citizenshipFilterCtrl.valueChanges
    //   .pipe(takeUntil(this._onDestroy))
    //   .subscribe(() => {
    //     this.filterCountryModels2();
    //   });
      
   

    this.formObserver = this.personalFormDetails.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => this.onFormChanged(this.personalFormDetails));

    this.currentlyResideInUSAValue.valueChanges.subscribe((resideInUSA) => {
        this.USCitizenCValue.setValue("yes");
    });
    this.USCitizenCValue.valueChanges.subscribe((USCitizen) => {
      if(USCitizen == 'yes')
      this.personalFormDetails.patchValue({
        citizenship: 'United States',
        greenCardHolder:null        
      }) 
    });

    this.servedUSArmedForcesValue.valueChanges.subscribe((USArmedForces) => {
      if (USArmedForces == "yes") {
        this.personalFormDetails.controls.militaryStatusControl.setValidators([
          Validators.required,
        ]);
        this.personalFormDetails.controls.militaryBranchControl.setValidators([
          Validators.required,
        ]);
        this.personalFormDetails.controls.MilitaryPayGrade.setValidators([
          Validators.required,
        ]);
      } else {
        this.personalFormDetails.controls.militaryStatusControl.setValidators(
          null
        );
        this.personalFormDetails.controls.militaryBranchControl.setValidators(
          null
        );
        this.personalFormDetails.controls.MilitaryPayGrade.setValidators(null);
      }
      this.personalFormDetails.controls.militaryBranchControl.updateValueAndValidity();
      this.personalFormDetails.controls.militaryStatusControl.updateValueAndValidity();
      this.personalFormDetails.controls.MilitaryPayGrade.updateValueAndValidity();
    });

    this.licensesCertificationsValue.valueChanges.subscribe((licenses) => {
      if (licenses == "yes") {
        this.personalFormDetails.controls.licenseCertificationName.setValidators(
          [Validators.required]
        );
      } else {
        this.personalFormDetails.controls.licenseCertificationName.setValidators(
          null
        );
      }
      this.personalFormDetails.controls.licenseCertificationName.updateValueAndValidity();
    });
    this.citizenshipValue.setValue('United States');
    this._connectionInterestService.FildShowValue.subscribe(
      (messageFildShowValue) => (this.fildShowvalue = messageFildShowValue)
    );

  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  // // country select
  // private filterCountryModels() {
  //   if (!this.countrynamelist) {
  //     return;
  //   }
  //   // get the search keyword
  //   let search = this.countryFilterCtrl.value;
  //   if (!search) {
  //     this.filteredCountryModels.next(this.countrynamelist.slice());
  //     return;
  //   } else {
  //     search = search.toLowerCase();
  //   }
  //   // filter the countrynamelist
  //   this.filteredCountryModels.next(
  //     this.countrynamelist.filter(
  //       (country) => country.name.toLowerCase().indexOf(search) > -1
  //     )
  //   );
  // }

  filterStates(ev){
    this.states=this.tempArrayStates;
    if(ev.target.value.length){
      let filter:String=ev.target.value.toLowerCase();
      this.states=this.states.filter(s => s.toLowerCase().startsWith(filter))
    }
  }

  applyValueState(value){
    this.personalFormDetails.get('personalDetailState').setValue(value);
  }
  // filterLoad(){
  //   let state=this.personalFormDetails.get('personalDetailCountry').value;
  //   if(state=='United States of America')
  //     state='United States';
  //   this.apiService.getStates(state).subscribe((data:any)=>{
  //     for(var i=0;i<data.data.states.length;i++){
  //       this.states.push(data.data.states[i]);
  //     }
  //     this.countrySelected=true;
  //   })
    
  // }
  countrySelected=false;

  loadStates(){
    if(this.personalFormDetails.get('personalDetailCountry').value){
      this.countrySelected=true;
      this.states = this.getStates(this.personalFormDetails.get('personalDetailCountry').value)
    }else{
      this.countrySelected=false;
      this.states=[]
    }
    // console.log(this.personalFormDetails.get('personalDetailCountry'))
    // this.states=[];
    // this.tempArrayStates=[];
    // this.personalFormDetails.get('personalDetailState').setValue(null);

  }

  getStates(country){
    if(!country){
      return [];
    }else{
      let stateOfCountry = countryStore.state.find(m=> m.country.toLowerCase() == country.toLowerCase());
      console.log(stateOfCountry)
      return stateOfCountry?.states

    }
    if(this.countryFilterCtrl.value){
      // this.states = countryStore.state.find(m=> m.country == this.countryFilterCtrl.value).states
      let temp = countryStore.state.find(m=> m.country.toLowerCase() == this.countryFilterCtrl.value.toLowerCase())
      if(temp)
      this.states = temp.states
      this.tempArrayStates = this.states;
    }
  }

  // // country select
  // private filterCountryModels2() {
  //   if (!this.countrynamelist) {
  //     return;
  //   }
  //   // get the search keyword
  //   let search = this.citizenshipFilterCtrl.value;
  //   if (!search) {
  //     this.filteredCitizenshipModels.next(this.countrynamelist.slice());
  //     return;
  //   } else {
  //     search = search.toLowerCase();
  //   }
  //   // filter the countrynamelist
  //   this.filteredCitizenshipModels.next(
  //     this.countrynamelist.filter(
  //       (country) => country.name.toLowerCase().indexOf(search) > -1
  //     )
  //   );
  // }

  constructor(
    private _snackBar: MatSnackBar,
    public _connectionInterestService: connectionInterestService,
    private registerService: RegisterService,
    private apiService:ApiService
  ) {
    setTimeout(() => {
      this.personalFormDetails?.controls['countryCode']?.valueChanges.subscribe(
        (value) => {
          this.filteredCountry = of(value).pipe(
            startWith(''),
            map(country => (country ? this.filterCountries(country) : this.countrynamelist.slice()))
          )
        }
      )
      
      this.personalFormDetails?.controls['personalDetailCountry']?.valueChanges.subscribe(
        (value) => {
          this.filteredCitzenship = of(value).pipe(
            startWith(''),
            map(country => (country ? this.filterCountries(country) : this.countrynamelist.slice()))
          )
        }
      )
      this.personalFormDetails?.controls['citizenship']?.valueChanges.subscribe(
        (value) => {
          this.nonUSCitizenship = of(value).pipe(
            startWith(''),
            map(country => (country ? this.filterCountries(country) : this.countrynamelist.slice()))
          )
        }
      )
  
      }, 100);
    this.progress = 0;
  }

  public filterCountries(value: string): CountryModel[] {
    const numberPattern = /^(\d+(\.\d+)?|\+\d+(\.\d+)?)$/;
   
    if(numberPattern.test(value)) {
     
      return this.countrynamelist.filter(country => country.number.includes(value));
    }else{
    let filterValue = value.toLowerCase();
    return this.countrynamelist.filter(country => country.name.toLowerCase().includes(filterValue));
    }
    
  }
  // progress bar data
  onFormChanged(form: UntypedFormGroup): void {
    this.progress = this.calculateFormProgress(form);
    this.progressBarData.emit(this.progress);
  }

  calculateFormProgress(form: UntypedFormGroup): number {
    let validCount = 0;
    for (const [key, value] of Object.entries(form.controls)) {
      if (value.value != "") validCount++;
    }
    return ((validCount * 0.34) / 45) * 100;
  }

  // get form controls
  get firstNameChangeValue() {
    return this.personalFormDetails.get("firstName") as UntypedFormControl;
  }
  // get lastNameChangedValue() {
  //   return this.personalFormDetails.get("lastNameChanged") as UntypedFormControl;
  // }

  // get previousLastNameValue() {
  //   return this.personalFormDetails.get("previousLastName") as UntypedFormControl;
  // }

  get currentlyResideInUSAValue() {
    return this.personalFormDetails.get("currentlyResideInUSA") as UntypedFormControl;
  }

  get USCitizenCValue() {
    return this.personalFormDetails.get("USCitizen") as UntypedFormControl;
  }
  get citizenshipValue() {
    return this.personalFormDetails.get("citizenship") as UntypedFormControl;
  }

  get greenCardHolderValue() {
    return this.personalFormDetails.get("greenCardHolder") as UntypedFormControl;
  }

  // get socialSecurityNumberValue() {
  //   return this.personalFormDetails.get("socialSecurityNumber") as UntypedFormControl;
  // }

  get servedUSArmedForcesValue() {
    return this.personalFormDetails.get("servedUSArmedForces") as UntypedFormControl;
  }

  get licensesCertificationsValue() {
    return this.personalFormDetails.get(
      "licensesCertifications"
    ) as UntypedFormControl;
  }

  displayProperty(value) {
    if (value) {
      return value.name;
    }
  }
  // edit form details
  persoanlDataEdit() {
    this.updateFormStateOnEdit();
    this._connectionInterestService.viewMode[2]=false;
    this.personalPhoneNumber =
      this.personalFormDetails.controls.phoneNumber.value;
    this.personalFormDetails.controls.phoneNumber.setValue(
      this.personalPhoneNumber.number,
      this.personalPhoneNumber.countryCode
    );
    this.personalFormDetails.controls.phoneNumber.updateValueAndValidity();

    // remove previous data from service
    this.registerService.updateData(this.personalDetailsFormData);
  }
  completed:any=false;
  // form submit
  personalDetailsFormData;
  findCountryByNumber(countryTofind: string): CountryModel | undefined {
    return this.countrynamelist.find((country) =>(country.number || country.name || country.iso) === countryTofind);
  }
  personalDetailsSubmit() {    
    this.completed=true
    this._connectionInterestService.viewMode[2]=true;
    let fildData = this._connectionInterestService.getValueChange()
    fildData[2]=false
    if(!this._connectionInterestService.viewMode[3])
    fildData[3]=true
    this._connectionInterestService.fldShowValueChange(fildData);

    let countryDetail = this.personalFormDetails.controls.countryCode.value;
    let country:any;
    if(countryDetail){
      country = this.findCountryByNumber(countryDetail)
    }
    let phoneNumber =  this.personalFormDetails.controls.phoneNumber.value;
    this.personalPhoneNumber = {
      "countryCode": country.iso,
      "dialCode": country.number,
      "id": "phone",
      "internationalNumber": country.number + " " + phoneNumber,
      "nationalNumber": "0 " + phoneNumber,
      "number": String(phoneNumber)
    }
    this.personalFormDetails.value.phoneNumber = this.personalPhoneNumber
     
  // this.changeCodeToISO(code);
    window.scrollTo({ top: 320, left: 0, behavior: "smooth" });
      this.personalDetailsFormData = this.personalFormDetails.value;
      this.registerService.pushData(this.personalDetailsFormData);
  }

  validateCheckYes(){
    this.validateCheckDisable=true;
  }
  validateCheck(){
    if(this.personalFormDetails.get('phoneNumber').valid && this.personalFormDetails.get('lastName').valid && this.personalFormDetails.get('email').valid ){
      this.validateCheckDisable=false;
    }
  }
  // update form active state for other forms when editing current form
  updateFormStateOnEdit() {
    let fildData = this._connectionInterestService.getValueChange()
    fildData[2]=true
    this._connectionInterestService.fldShowValueChange(fildData);
    
  }

  // changeCodeToISO(code){
  //   switch(code){
  //     case "+1":{
  //       this.selectedCountry=CountryISO.UnitedStates;
  //       break;
  //     }
  //     case "+44":{
  //       this.selectedCountry=CountryISO.UnitedKingdom;
  //       break;
  //     }
  //     case "+93":{
  //       this.selectedCountry=CountryISO.Afghanistan;
  //       break;
  //     }
  //     case "+355":{
  //       this.selectedCountry=CountryISO.Albania;
  //       break;
  //     }
  //     case "+213":{
  //       this.selectedCountry=CountryISO.Algeria;
  //       break;
  //     }
  //     case "+376":{
  //       this.selectedCountry=CountryISO.Andorra;
  //       break;
  //     }
  //     case "+244":{
  //       this.selectedCountry=CountryISO.Angola;
  //       break;
  //     }
  //     case "+1 264":{
  //       this.selectedCountry=CountryISO.Anguilla;
  //       break;
  //     }
  //     case "+1268":{
  //       this.selectedCountry=CountryISO.AntiguaAndBarbuda;
  //       break;
  //     }
  //     case "+54":{
  //       this.selectedCountry=CountryISO.Argentina;
  //       break;
  //     }
  //     case "+374":{
  //       this.selectedCountry=CountryISO.Armenia;
  //       break;
  //     }
  //     case "+297":{
  //       this.selectedCountry=CountryISO.Aruba;
  //       break;
  //     }
  //     case "+61":{
  //       this.selectedCountry=CountryISO.Australia;
  //       break;
  //     }
  //     case "+43":{
  //       this.selectedCountry=CountryISO.Austria;
  //       break;
  //     }
  //     case "+994":{
  //       this.selectedCountry=CountryISO.Azerbaijan;
  //       break;
  //     }
  //     case "+1 242":{
  //       this.selectedCountry=CountryISO.Bahamas;
  //       break;
  //     }
  //     case "+973":{
  //       this.selectedCountry=CountryISO.Bahrain;
  //       break;
  //     }
  //     case "+880":{
  //       this.selectedCountry=CountryISO.Bangladesh;
  //       break;
  //     }
  //     case "+1 246":{
  //       this.selectedCountry=CountryISO.Barbados;
  //       break;
  //     }
  //     case "+375":{
  //       this.selectedCountry=CountryISO.Belarus;
  //       break;
  //     }
  //     case "+32":{
  //       this.selectedCountry=CountryISO.Belgium;
  //       break;
  //     }
  //     case "+501":{
  //       this.selectedCountry=CountryISO.Belize;
  //       break;
  //     }
  //     case "+229":{
  //       this.selectedCountry=CountryISO.Benin;
  //       break;
  //     }
  //     case "+1 441":{
  //       this.selectedCountry=CountryISO.Bermuda;
  //       break;
  //     }
  //     case "+975":{
  //       this.selectedCountry=CountryISO.Bhutan;
  //       break;
  //     }
  //     case "+387":{
  //       this.selectedCountry=CountryISO.BosniaAndHerzegovina;
  //       break;
  //     }
  //     case "+267":{
  //       this.selectedCountry=CountryISO.Botswana;
  //       break;
  //     }
  //     case "+55":{
  //       this.selectedCountry=CountryISO.Brazil;
  //       break;
  //     }
  //     case "+246":{
  //       this.selectedCountry=CountryISO.BritishIndianOceanTerritory;
  //       break;
  //     }
  //     case "+673":{
  //       this.selectedCountry=CountryISO.Brunei;
  //       break;
  //     }
  //     case "+359":{
  //       this.selectedCountry=CountryISO.Bulgaria;
  //       break;
  //     }
  //     case "+226":{
  //       this.selectedCountry=CountryISO.BurkinaFaso;
  //       break;
  //     }
  //     case "+257":{
  //       this.selectedCountry=CountryISO.Burundi;
  //       break;
  //     }
  //     case "+855":{
  //       this.selectedCountry=CountryISO.Cambodia;
  //       break;
  //     }
  //     case "+237":{
  //       this.selectedCountry=CountryISO.Cameroon;
  //       break;
  //     }
  //     case "+238":{
  //       this.selectedCountry=CountryISO.CapeVerde;
  //       break;
  //     }
  //     case "+1345":{
  //       this.selectedCountry=CountryISO.CaymanIslands;
  //       break;
  //     }
  //     case "+236":{
  //       this.selectedCountry=CountryISO.CentralAfricanRepublic;
  //       break;
  //     }
  //     case "+235":{
  //       this.selectedCountry=CountryISO.Chad;
  //       break;
  //     }
  //     case "+56":{
  //       this.selectedCountry=CountryISO.Chile;
  //       break;
  //     }
  //     case "+86":{
  //       this.selectedCountry=CountryISO.China;
  //       break;
  //     }
  //     case "+61":{
  //       this.selectedCountry=CountryISO.ChristmasIsland;
  //       break;
  //     }
  //     case "+57":{
  //       this.selectedCountry=CountryISO.Colombia;
  //       break;
  //     }
  //     case "+269":{
  //       this.selectedCountry=CountryISO.Comoros;
  //       break;
  //     }
  //     case "+242":{
  //       this.selectedCountry=CountryISO.CongoRepublicCongoBrazzaville;
  //       break;
  //     }
  //     case "+682":{
  //       this.selectedCountry=CountryISO.CookIslands;
  //       break;
  //     }
  //     case "+506":{
  //       this.selectedCountry=CountryISO.CostaRica;
  //       break;
  //     }
  //     case "+385":{
  //       this.selectedCountry=CountryISO.Croatia;
  //       break;
  //     }
  //     case "+53":{
  //       this.selectedCountry=CountryISO.Cuba;
  //       break;
  //     }
  //     case "+357":{
  //       this.selectedCountry=CountryISO.Cyprus;
  //       break;
  //     }
  //     case "+420":{
  //       this.selectedCountry=CountryISO.CzechRepublic;
  //       break;
  //     }
  //     case "+45":{
  //       this.selectedCountry=CountryISO.Denmark;
  //       break;
  //     }
  //     case "+253":{
  //       this.selectedCountry=CountryISO.Djibouti;
  //       break;
  //     }
  //     case "+1 767":{
  //       this.selectedCountry=CountryISO.Dominica;
  //       break;
  //     }
  //     case "+1 849":{
  //       this.selectedCountry=CountryISO.DominicanRepublic;
  //       break;
  //     }
  //     case "+593":{
  //       this.selectedCountry=CountryISO.Ecuador;
  //       break;
  //     }
  //     case "+20":{
  //       this.selectedCountry=CountryISO.Egypt;
  //       break;
  //     }
  //     case "+503":{
  //       this.selectedCountry=CountryISO.ElSalvador;
  //       break;
  //     }
  //     case "+240":{
  //       this.selectedCountry=CountryISO.EquatorialGuinea;
  //       break;
  //     }
  //     case "+291":{
  //       this.selectedCountry=CountryISO.Eritrea;
  //       break;
  //     }
  //     case "+372":{
  //       this.selectedCountry=CountryISO.Estonia;
  //       break;
  //     }
  //     case "+251":{
  //       this.selectedCountry=CountryISO.Ethiopia;
  //       break;
  //     }
  //     case "+500":{
  //       this.selectedCountry=CountryISO.FalklandIslands;
  //       break;
  //     }
  //     case "+298":{
  //       this.selectedCountry=CountryISO.FaroeIslands;
  //       break;
  //     }
  //     case "+679":{
  //       this.selectedCountry=CountryISO.Fiji;
  //       break;
  //     }
  //     case "+358":{
  //       this.selectedCountry=CountryISO.Finland;
  //       break;
  //     }
  //     case "+33":{
  //       this.selectedCountry=CountryISO.France;
  //       break;
  //     }
  //     case "+594":{
  //       this.selectedCountry=CountryISO.FrenchGuiana;
  //       break;
  //     }
  //     case "+689":{
  //       this.selectedCountry=CountryISO.FrenchPolynesia;
  //       break;
  //     }
  //     case "+241":{
  //       this.selectedCountry=CountryISO.Gabon;
  //       break;
  //     }
  //     case "+220":{
  //       this.selectedCountry=CountryISO.Gambia;
  //       break;
  //     }
  //     case "+995":{
  //       this.selectedCountry=CountryISO.Georgia;
  //       break;
  //     }
  //     case "+49":{
  //       this.selectedCountry=CountryISO.Germany;
  //       break;
  //     }
  //     case "+233":{
  //       this.selectedCountry=CountryISO.Ghana;
  //       break;
  //     }
  //     case "+250":{
  //       this.selectedCountry=CountryISO.Gibraltar;
  //       break;
  //     }
  //     case "+30":{
  //       this.selectedCountry=CountryISO.Greece;
  //       break;
  //     }
  //     case "+299":{
  //       this.selectedCountry=CountryISO.Greenland;
  //       break;
  //     }
  //     case "+1 473":{
  //       this.selectedCountry=CountryISO.Grenada;
  //       break;
  //     }
  //     case "+590":{
  //       this.selectedCountry=CountryISO.Guadeloupe;
  //       break;
  //     }
  //     case "+1 671":{
  //       this.selectedCountry=CountryISO.Guam;
  //       break;
  //     }
  //     case "+502":{
  //       this.selectedCountry=CountryISO.Guatemala;
  //       break;
  //     }
  //     case "+44":{
  //       this.selectedCountry=CountryISO.Guernsey;
  //       break;
  //     }
  //     case "+224":{
  //       this.selectedCountry=CountryISO.Guinea;
  //       break;
  //     }
  //     case "+245":{
  //       this.selectedCountry=CountryISO.GuineaBissau;
  //       break;
  //     }
  //     case "+592":{
  //       this.selectedCountry=CountryISO.Guyana;
  //       break;
  //     }
  //     case "+509":{
  //       this.selectedCountry=CountryISO.Haiti;
  //       break;
  //     }
  //     case "+504":{
  //       this.selectedCountry=CountryISO.Honduras;
  //       break;
  //     }
  //     case "+852":{
  //       this.selectedCountry=CountryISO.HongKong;
  //       break;
  //     }
  //     case "+36":{
  //       this.selectedCountry=CountryISO.Hungary;
  //       break;
  //     }
  //     case "+354":{
  //       this.selectedCountry=CountryISO.Iceland;
  //       break;
  //     }
  //     case "+91":{
  //       this.selectedCountry=CountryISO.India;
  //       break;
  //     }
  //     case "+62":{
  //       this.selectedCountry=CountryISO.Indonesia;
  //       break;
  //     }
  //     case "+964":{
  //       this.selectedCountry=CountryISO.Iraq;
  //       break;
  //     }
  //     case "+353":{
  //       this.selectedCountry=CountryISO.Ireland;
  //       break;
  //     }
  //     case "+972":{
  //       this.selectedCountry=CountryISO.Israel;
  //       break;
  //     }
  //     case "+39":{
  //       this.selectedCountry=CountryISO.Italy;
  //       break;
  //     }
  //     case "+98":{
  //       this.selectedCountry=CountryISO.Iran;
  //       break;
  //     }
  //     case "+1 876":{
  //       this.selectedCountry=CountryISO.Jamaica;
  //       break;
  //     }
  //     case "+81":{
  //       this.selectedCountry=CountryISO.Japan;
  //       break;
  //     }
  //     case "+962":{
  //       this.selectedCountry=CountryISO.Jordan;
  //       break;
  //     }
  //     case "+7":{
  //       this.selectedCountry=CountryISO.Kazakhstan;
  //       break;
  //     }
  //     case "+254":{
  //       this.selectedCountry=CountryISO.Kenya;
  //       break;
  //     }
  //     case "686":{
  //       this.selectedCountry=CountryISO.Kiribati;
  //       break;
  //     }
  //     case "+965":{
  //       this.selectedCountry=CountryISO.Kuwait;
  //       break;
  //     }
  //     case "+996":{
  //       this.selectedCountry=CountryISO.Kyrgyzstan;
  //       break;
  //     }
  //     // {
  //     //   name: "Lao People's Democratic Republic",
  //     //   flag: "https://upload.wikimedia.org/wikipedia/commons/5/56/Flag_of_Laos.svg",
  //     //   number: "+856",
  //     // },
  //     case "+371":{
  //       this.selectedCountry=CountryISO.Latvia;
  //       break;
  //     }
  //     case "+961":{
  //       this.selectedCountry=CountryISO.Lebanon;
  //       break;
  //     }
  //     case "+266":{
  //       this.selectedCountry=CountryISO.Lesotho;
  //       break;
  //     }
  //     case "+231":{
  //       this.selectedCountry=CountryISO.Liberia;
  //       break;
  //     }
  //     case "+423":{
  //       this.selectedCountry=CountryISO.Liechtenstein;
  //       break;
  //     }
  //     case "+370":{
  //       this.selectedCountry=CountryISO.Lithuania;
  //       break;
  //     }
  //     case "+352":{
  //       this.selectedCountry=CountryISO.Luxembourg;
  //       break;
  //     }
  //     case "+853":{
  //       this.selectedCountry=CountryISO.Macau;
  //       break;
  //     }
  //     case "+261":{
  //       this.selectedCountry=CountryISO.Madagascar;
  //       break;
  //     }
  //     case "+265":{
  //       this.selectedCountry=CountryISO.Malawi;
  //       break;
  //     }
  //     case "+60":{
  //       this.selectedCountry=CountryISO.Malaysia;
  //       break;
  //     }
  //     case "+960":{
  //       this.selectedCountry=CountryISO.Maldives;
  //       break;
  //     }
  //     case "+223":{
  //       this.selectedCountry=CountryISO.Mali;
  //       break;
  //     }
  //     case "+356":{
  //       this.selectedCountry=CountryISO.Malta;
  //       break;
  //     }
  //     case "+692":{
  //       this.selectedCountry=CountryISO.MarshallIslands;
  //       break;
  //     }
  //     case "+596":{
  //       this.selectedCountry=CountryISO.Martinique;
  //       break;
  //     }
  //     case "+222":{
  //       this.selectedCountry=CountryISO.Mauritania;
  //       break;
  //     }
  //     case "+230":{
  //       this.selectedCountry=CountryISO.Mauritius;
  //       break;
  //     }
  //     case "+262":{
  //       this.selectedCountry=CountryISO.Mayotte;
  //       break;
  //     }
  //     case "+52":{
  //       this.selectedCountry=CountryISO.Mexico;
  //       break;
  //     }
  //     case "+377":{
  //       this.selectedCountry=CountryISO.Monaco;
  //       break;
  //     }
  //     case "+976":{
  //       this.selectedCountry=CountryISO.Mongolia;
  //       break;
  //     }
  //     case "+382":{
  //       this.selectedCountry=CountryISO.Montenegro;
  //       break;
  //     }
  //     case "+1664":{
  //       this.selectedCountry=CountryISO.Montserrat;
  //       break;
  //     }
  //     case "+212":{
  //       this.selectedCountry=CountryISO.Morocco;
  //       break;
  //     }
  //     case "+258":{
  //       this.selectedCountry=CountryISO.Mozambique;
  //       break;
  //     }
  //     case "+95":{
  //       this.selectedCountry=CountryISO.Myanmar;
  //       break;
  //     }
  //     case "+264":{
  //       this.selectedCountry=CountryISO.Namibia;
  //       break;
  //     }
  //     case "+674":{
  //       this.selectedCountry=CountryISO.Nauru;
  //       break;
  //     }
  //     case "+977":{
  //       this.selectedCountry=CountryISO.Nepal;
  //       break;
  //     }
  //     case "+31":{
  //       this.selectedCountry=CountryISO.Netherlands;
  //       break;
  //     }
  //     case "+687":{
  //       this.selectedCountry=CountryISO.NewCaledonia;
  //       break;
  //     }
  //     case "+64":{
  //       this.selectedCountry=CountryISO.NewZealand;
  //       break;
  //     }
  //     case "+505":{
  //       this.selectedCountry=CountryISO.Nicaragua;
  //       break;
  //     }
  //     case "+227":{
  //       this.selectedCountry=CountryISO.Niger;
  //       break;
  //     }
  //     case "+234":{
  //       this.selectedCountry=CountryISO.Niger;
  //       break;
  //     }
  //     case "+683":{
  //       this.selectedCountry=CountryISO.Niue;
  //       break;
  //     }
  //     case "+672":{
  //       this.selectedCountry=CountryISO.NorfolkIsland;
  //       break;
  //     }
  //     case "+1 670":{
  //       this.selectedCountry=CountryISO.NorthernMarianaIslands;
  //       break;
  //     }
  //     case "+47":{
  //       this.selectedCountry=CountryISO.Norway;
  //       break;
  //     }
  //     case "+968":{
  //       this.selectedCountry=CountryISO.Oman;
  //       break;
  //     }
  //     case "+92":{
  //       this.selectedCountry=CountryISO.Pakistan;
  //       break;
  //     }
  //     case "+680":{
  //       this.selectedCountry=CountryISO.Palau;
  //       break;
  //     }
  //     case "+507":{
  //       this.selectedCountry=CountryISO.Panama;
  //       break;
  //     }
  //     case "+675":{
  //       this.selectedCountry=CountryISO.PapuaNewGuinea;
  //       break;
  //     }
  //     case "+595":{
  //       this.selectedCountry=CountryISO.Paraguay;
  //       break;
  //     }
  //     case "+51":{
  //       this.selectedCountry=CountryISO.Peru;
  //       break;
  //     }
  //     case "+63":{
  //       this.selectedCountry=CountryISO.Philippines;
  //       break;
  //     }
  //     // {
  //     //   name: "Pitcairn",
  //     //   flag: "https://upload.wikimedia.org/wikipedia/commons/8/88/Flag_of_the_Pitcairn_Islands.svg",
  //     //   number: "+870",
  //     // },
  //     case "+48":{
  //       this.selectedCountry=CountryISO.Poland;
  //       break;
  //     }
  //     case "+351":{
  //       this.selectedCountry=CountryISO.Portugal;
  //       break;
  //     }
  //     case "+1 939":{
  //       this.selectedCountry=CountryISO.PuertoRico;
  //       break;
  //     }
  //     case "+974":{
  //       this.selectedCountry=CountryISO.Qatar;
  //       break;
  //     }
  //     case "+262":{
  //       this.selectedCountry=CountryISO.Réunion;
  //       break;
  //     }
  //     case "+40":{
  //       this.selectedCountry=CountryISO.Romania;
  //       break;
  //     }
  //     case "+250":{
  //       this.selectedCountry=CountryISO.Rwanda;
  //       break;
  //     }
  //     case "+1 869":{
  //       this.selectedCountry=CountryISO.SaintKittsAndNevis;
  //       break;
  //     }
  //     case "+1 758":{
  //       this.selectedCountry=CountryISO.SaintLucia;
  //       break;
  //     }
  //     case "+508":{
  //       this.selectedCountry=CountryISO.SaintPierreAndMiquelon;
  //       break;
  //     }
  //     case "+1 784":{
  //       this.selectedCountry=CountryISO.SaintVincentAndTheGrenadines;
  //       break;
  //     }
  //     case "+68":{
  //       this.selectedCountry=CountryISO.Samoa;
  //       break;
  //     }
  //     case "+378":{
  //       this.selectedCountry=CountryISO.SanMarino;
  //       break;
  //     }
  //     case "+239":{
  //       this.selectedCountry=CountryISO.SãoToméAndPríncipe;
  //       break;
  //     }
  //     case "+966":{
  //       this.selectedCountry=CountryISO.SaudiArabia;
  //       break;
  //     }
  //     case "+221":{
  //       this.selectedCountry=CountryISO.Senegal;
  //       break;
  //     }
  //     case "+381":{
  //       this.selectedCountry=CountryISO.Serbia;
  //       break;
  //     }
  //     case "+248":{
  //       this.selectedCountry=CountryISO.Seychelles;
  //       break;
  //     }
  //     case "+232":{
  //       this.selectedCountry=CountryISO.SierraLeone;
  //       break;
  //     }
  //     case "+65":{
  //       this.selectedCountry=CountryISO.Singapore;
  //       break;
  //     }
  //     case "+421":{
  //       this.selectedCountry=CountryISO.Slovakia;
  //       break;
  //     }
  //     case "+386":{
  //       this.selectedCountry=CountryISO.Slovenia;
  //       break;
  //     }
  //     case "+677":{
  //       this.selectedCountry=CountryISO.SolomonIslands;
  //       break;
  //     }
  //     case "+252":{
  //       this.selectedCountry=CountryISO.Somalia;
  //       break;
  //     }
  //     case "+27":{
  //       this.selectedCountry=CountryISO.SouthAfrica;
  //       break;
  //     }
  //     // {
  //     //   name: "South Georgia and the South Sandwich Islands",
  //     //   flag: "https://upload.wikimedia.org/wikipedia/commons/e/ed/Flag_of_South_Georgia_and_the_South_Sandwich_Islands.svg",
  //     //   number: "+500",
  //     // },
  //     case "+34":{
  //       this.selectedCountry=CountryISO.Spain;
  //       break;
  //     }
  //     case "+94":{
  //       this.selectedCountry=CountryISO.SriLanka;
  //       break;
  //     }
  //     case "+249":{
  //       this.selectedCountry=CountryISO.Sudan;
  //       break;
  //     }
  //     case "+597":{
  //       this.selectedCountry=CountryISO.Suriname;
  //       break;
  //     }
  //     case "+268":{
  //       this.selectedCountry=CountryISO.Swaziland;
  //       break;
  //     }
  //     case "+46":{
  //       this.selectedCountry=CountryISO.Sweden;
  //       break;
  //     }
  //     case "+41":{
  //       this.selectedCountry=CountryISO.Switzerland;
  //       break;
  //     }
  //     // {
  //     //   name: "Syrian Arab Republic",
  //     //   flag: "https://upload.wikimedia.org/wikipedia/commons/5/53/Flag_of_Syria.svg",
  //     //   number: "+963",
  //     // },
  //     case "+886":{
  //       this.selectedCountry=CountryISO.Taiwan;
  //       break;
  //     }
  //     case "+992":{
  //       this.selectedCountry=CountryISO.Tajikistan;
  //       break;
  //     }
  //     case "+66":{
  //       this.selectedCountry=CountryISO.Thailand;
  //       break;
  //     }
  //     case "+670":{
  //       this.selectedCountry=CountryISO.TimorLeste;
  //       break;
  //     }
  //     case "+228":{
  //       this.selectedCountry=CountryISO.Togo;
  //       break;
  //     }
  //     case "+690":{
  //       this.selectedCountry=CountryISO.Tokelau;
  //       break;
  //     }
  //     case "+676":{
  //       this.selectedCountry=CountryISO.Tonga;
  //       break;
  //     }
  //     case "+1 868":{
  //       this.selectedCountry=CountryISO.TrinidadAndTobago;
  //       break;
  //     }
  //     case "+216":{
  //       this.selectedCountry=CountryISO.Tunisia;
  //       break;
  //     }
  //     case "+90":{
  //       this.selectedCountry=CountryISO.Turkey;
  //       break;
  //     }
  //     case "+993":{
  //       this.selectedCountry=CountryISO.Turkmenistan;
  //       break;
  //     }
  //     case "+1 649":{
  //       this.selectedCountry=CountryISO.TurksAndCaicosIslands;
  //       break;
  //     }
  //     case "+688":{
  //       this.selectedCountry=CountryISO.Tuvalu;
  //       break;
  //     }
  //     case "+256":{
  //       this.selectedCountry=CountryISO.Uganda;
  //       break;
  //     }
  //     case "+380":{
  //       this.selectedCountry=CountryISO.Ukraine;
  //       break;
  //     }
  //     case "+971":{
  //       this.selectedCountry=CountryISO.UnitedArabEmirates;
  //       break;
  //     }
  //     // {
  //     //   name: "United States Minor Outlying Islands",
  //     //   flag: "https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg",
  //     //   number: "+1581",
  //     // },
  //     case "+598":{
  //       this.selectedCountry=CountryISO.Uruguay;
  //       break;
  //     }
  //     case "+998":{
  //       this.selectedCountry=CountryISO.Uzbekistan;
  //       break;
  //     }
  //     case "+678":{
  //       this.selectedCountry=CountryISO.Vanuatu;
  //       break;
  //     }
  //     case "+84":{
  //       this.selectedCountry=CountryISO.Vietnam;
  //       break;
  //     }
  //     case "+681":{
  //       this.selectedCountry=CountryISO.WallisAndFutuna;
  //       break;
  //     }
  //     case "+967":{
  //       this.selectedCountry=CountryISO.Yemen;
  //       break;
  //     }
  //     case "+260":{
  //       this.selectedCountry=CountryISO.Zambia;
  //       break;
  //     }
  //     case "+263":{
  //       this.selectedCountry=CountryISO.Zimbabwe;
  //       break;
  //     }
  //   }
  // }

  // suffix data
  suffix: Suffix[] = [
    { value: "1", viewValue: "I" },
    { value: "2", viewValue: "II" },
    { value: "3", viewValue: "III" },
    { value: "4", viewValue: "IV" },
    { value: "3", viewValue: "V" },
    { value: "jd", viewValue: "J.D" },
    { value: "jr", viewValue: "J.r" },
    { value: "lld", viewValue: "LL.D" },
    { value: "md", viewValue: "M.D" },
    { value: "phd", viewValue: "Ph.D" },
  ];

  applicableRacesAndEthnicities: any[] = [
    "Asian",
    "Hispanic/Latino, Other",
    "Hispanic/Latino, Mexican",
    "Hispanic/Latino, Puerto Rico",
    "Native Hawaiian or Pacific Islander",
    "White",
    "Opt-out of Survey",
  ];

  militaryStatusValue: any[] = [
    "Active Duty",
    "National Guard",
    "Reserves",
    "Discharged",
    "Retired",
    "Dependent - Spouse",
  ];

  militaryBranchValue: any[] = [
    "Air Force",
    "Army",
    "Coast Guard",
    "Marine Corps",
    "Navy",
  ];

  MilitaryPayGradeValue: any[] = [
    "E1",
    "E2",
    "E3",
    "E4",
    "E5",
    "E6",
    "E7",
    "E8",
    "E9",
    "CW1",
    "CW2",
    "CW3",
    "CW4",
    "CW5",
    "01",
    "02",
    "03",
    "04",
  ];
}

@Component({
  selector: "personal-details-snack",
  template: "<p class='text_13'>Please enter your personal details. </p>",
})
export class personalDetailsNotification {}
