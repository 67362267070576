import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class connectionInterestService {
  scrollHeight=0;
  scrollHeight1=0;
  personalDataSource = new BehaviorSubject([true, false, false, false]);
  viewMode = new BehaviorSubject([false, false, false, false]);
  FildShowValue = this.personalDataSource.asObservable();

  fldShowValueChange(messageFildShowValue: any) {
    this.personalDataSource.next(messageFildShowValue)
  }

  getValueChange() {
    return this.personalDataSource.getValue();
  }

  private formSubmiteValue = new BehaviorSubject(false);
  formSubmited = this.formSubmiteValue.asObservable();

  formSubmitedData(formSubmitedValid: any) {
    this.formSubmiteValue.next(formSubmitedValid);
  }
}