import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import {
  trigger,
  state,
  animate,
  transition,
  style,
} from "@angular/animations";
// import { MatAccordion } from "@angular/material";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { connectionInterestService } from "src/app/service/connection.service";
import { ApiService } from "src/app/service/api.service";
import { RegisterService } from "src/app/service/register.service";
// import { hostViewClassName } from "@angular/compiler";
import { ActivatedRoute } from "@angular/router";
import { MatAccordion } from "@angular/material/expansion";

// Program Class Attend Type DATs
let programTypeApi = [
  {
    program_type_name: "Online Degree",
    class_type: "Attend classes online",
    program_type_icon: "assets/images/online.svg",
    program_value: "onlineDegree",
  },
  {
    program_type_name: "Residential degree",
    class_type: "Attend classes near you",
    program_type_icon: "assets/images/residential.svg",
    program_value: "residentialDegree",
  },
];

@Component({
  selector: "app-academic-interestion",
  templateUrl: "./academic-interestion.component.html",
  animations: [
    trigger("height4sAnim", [
      state("true", style({ opacity: 1, height: "auto" })),
      state("void", style({ opacity: 0, height: 0 })),
      transition("* => *", [animate("0.4s")]),
    ]),
    trigger("btnStep3sAnim", [
      state("true", style({ "margin-left": 0, opacity: 0 })),
      state("void", style({ opacity: 1, "margin-left": -280, "z-index": 99 })),
      transition("* => *", [animate("0.3s")]),
    ]),
    trigger("height2sAnim", [
      state("true", style({ opacity: 1, height: "auto" })),
      state("void", style({ opacity: 0, height: 0 })),
      transition("* => *", [animate("0.2s")]),
    ]),
    trigger("balloonEffect", [
      state(
        "true",
        style({ backgroundColor: "green", transform: "scale(1.5)" })
      ),
      state("void", style({ backgroundColor: "red", transform: "scale(1)" })),
      transition("* => *", [animate("2s")]),
    ]),
  ],
})
export class AcademicInterestionComponent implements OnInit {
  countryList: any = "countries";
  academicDetails: FormGroup;
  programTypeEditView: boolean = false;
  programLevelEditView: boolean = false;
  personalInfoFormValid$: any;
  subscription: Subscription;
  programLevelData: any;
  programTypeData: any;
  selectedProgram: any;
  selectedDegreeTitle: any;
  academicSortDataShow: boolean;
  fildShowvalue: boolean[];
  dualEnrollmentCollegeDetails: FormArray;
  checkbtn:any=false;
  checkbtn0:any=false;
  resPre=false;
  onlinePre=false;
  academicData = [];
  academicDegreeData = [];
  academicDegreeLevelData = [];
  academicPogramLevelData = [];
  urlQuery=false;
  programListPreSelect:any;
  location:any=[{name:"Baton Rouge, LA",id:2260},{name:"Cleveland, OH",id:2259},{name:"Dallas, TX(Garland)",id:2247},{name:"Fort Worth, TX",id:2248},{name:"North Housten (Greenspoint, TX)",id:2257},{name:"Housten Southest,TX",id:2261},{name:"Knoxville, TN",id:5140},{name:"Lafayette, LA",id:2249},{name:"Memphis, TN",id:2251},{name:"Mobile, AL",id:2253},{name:"Nashville, TN",id:2252},{name:"Shreveport, LA",id:2254}];
  isAcademicViewMode: boolean = false;
  editForm: boolean = false;
  public locDefault:any;
  public progress: number;
  public formObserver: Subscription;

  @Output() private academicProgramTypePass = new EventEmitter<any>();
  @Output() progressBarData = new EventEmitter<any>();
  @ViewChild("academicAccordion", { static: false })



  academicAccordion: MatAccordion;

  // remove duplicate items from an array
  removeDuplicates(array) {
    return array.filter((a, b) => array.indexOf(a) === b);
  }

  // set program Class Attend Type
  getProgramTypeApi() {
    this.programTypeData = programTypeApi;
  }

  // get filterd data from API by Class Attend Type
  public programType(value) {
    const programTypeDetail = value;
    let d=document.getElementById('info2');
    let ele=d.offsetTop;
    d.scrollIntoView({behavior:"smooth"});


    // get filterd degreeType data
    if (programTypeDetail === "onlineDegree") {
      this.checkbtn= false;
      this.checkbtn0=true
      this.academicDegreeData = this.academicData.filter(
        (degreeType) => degreeType.is_online === 1
      );
    }
    if (programTypeDetail === "residentialDegree") {
      this.checkbtn= true;
      this.checkbtn0=false
      this.academicDegreeData = this.academicData.filter(
        (degreeType) => degreeType.is_online === 0
      );
    }
    let academicDegreeData2=[{name:`Bachelor`,src:'assets/images/bachelors.svg'},{name:`Associate`,src:'assets/images/associates.png'},{name:`Diploma`,src:'assets/images/diploma.png'}]
    // get filterd degreeLevel data
    this.academicDegreeLevelData = academicDegreeData2.map(
      (degreeLvel) => degreeLvel
    );
    // this.academicDegreeLevelData = this.removeDuplicates(
    //   academicDegreeLevelData2
    // );
    // this.academicProgramTypePass.emit(programTypeDetail);    
  }
  param1:any;
  param2:any;
  constructor(
    private academicDetailsBuilder: FormBuilder,
    public _connectionInterestService: connectionInterestService,
    private apiService: ApiService,
    private registerService: RegisterService,
    private route:ActivatedRoute,

  ) {
    this.getProgramTypeApi();

    this.progress = 0;
  }
  chosen:any;
  ngOnInit() {
    // this.academicDetails.get('degreeType').setValue({
    //   program_type_name: "Online Degree",
    //   class_type: "Attend classes online",
    //   program_type_icon: "assets/images/online_degree_icon.png",
    //   program_value: "onlineDegree",
    // })
    this.apiService.getAcademicList().subscribe((data:any[])=>{
      this.academicData = data;
    })

    this.academicDetails = this.academicDetailsBuilder.group({
      degreeType: new FormControl("", Validators.required),
      degreeLocation:new FormControl(""),
      degreeLevel: new FormControl("", ),
      programList: new FormControl("",Validators.required),
    });


    this.route.queryParams.subscribe((params:any)=>{
      this.param1=params['loc'];
      this.param2=params['prg'];
      if(!this.editForm)this.setUrlParams();
    })


    this._connectionInterestService.FildShowValue.subscribe(
      (fildShowvalue) => (this.fildShowvalue = fildShowvalue)
    );

    this.formObserver = this.academicDetails.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => this.onFormChanged(this.academicDetails));

    // get API data from apiService Service


    this.academicDetails.get('degreeType').valueChanges.subscribe(val => {
          this.academicDetails.patchValue({
            degreeLocation : null,
            degreeLevel : null
          })
    });

  }
  onFormChanged(form: FormGroup): void {
    this.progress = this.calculateFormProgress(form);
    this.progressBarData.emit(this.progress);
  }

  setUrlParams(){
    if(this.param1 && this.param1!=2256){

      this.academicDetails.get('degreeType').setValue('residentialDegree');
      this.urlQuery=true;
      this.checkbtn=true;
      this.resPre=true;
      this.apiService.getAcademicList().subscribe((data: any[]) => {
        this.academicData = data;
        this.academicDegreeData = this.academicData.filter(
          (degreeType) => degreeType.is_online === 0
        );
        this.locDefault=this.location.filter(
          (item)=> item.id==this.param1
        )
        
        this.locDefault=this.locDefault[0];
        this.academicDetails.patchValue({degreeLocation:this.locDefault});
        switch(this.locDefault.name){
          case 'Baton Rouge, LA':{
            this.academicPogramLevelData = this.academicDegreeData.filter(
              (item) => (item.id == 8125 || item.id == 8120 || item.id == 9875)
            );
            this.filterdDegreeTypeList = this.academicPogramLevelData.map(
              (item) => item
            );
            if(this.param2){

                for(let i=0;i<this.filterdDegreeTypeList.length;i++){

                  if(this.param2==this.filterdDegreeTypeList[i].id){
                    this.programListPreSelect=i;
                    this.academicDetails.get('programList').setValue(this.filterdDegreeTypeList[i]);
                    break;
                  }
                }
            }
            break;
          }
          case 'Cleveland, OH':{
            this.academicPogramLevelData = this.academicDegreeData.filter(
              (item) => (item.id == 10843 || item.id == 8123 || item.id == 9875 || item.id == 9130)
            );
            this.filterdDegreeTypeList = this.academicPogramLevelData.map(
              (item) => item
            );
            if(this.param2){

                for(let i=0;i<this.filterdDegreeTypeList.length;i++){

                  if(this.param2==this.filterdDegreeTypeList[i].id){
                    this.programListPreSelect=i;
                    this.academicDetails.get('programList').setValue(this.filterdDegreeTypeList[i]);
                    break;
                  }
                }
            }
            break;
          }
          case 'Dallas, TX(Garland)':{
            this.academicPogramLevelData = this.academicDegreeData.filter(
              (item) => (item.id == 10843 || item.id == 8099 || item.id == 8146 || item.id == 8253 || item.id == 8125 || item.id == 9875 || item.id == 8115 || item.id == 8128 || item.id == 9258)
            );
            this.filterdDegreeTypeList = this.academicPogramLevelData.map(
              (item) => item
            );
            if(this.param2){

                for(let i=0;i<this.filterdDegreeTypeList.length;i++){

                  if(this.param2==this.filterdDegreeTypeList[i].id){
                    this.programListPreSelect=i;
                    this.academicDetails.get('programList').setValue(this.filterdDegreeTypeList[i]);
                    break;
                  }
                }
            }
            break;
          }
          case 'Fort Worth, TX':{
            this.academicPogramLevelData = this.academicDegreeData.filter(
              (item) => (item.id == 10843 || item.id == 9815 || item.id == 8123 || item.id == 8253 || item.id == 8144 || item.id == 9875 || item.id == 8115 || item.id == 8128 || item.id == 9130 || item.id == 9255)
            );
            this.filterdDegreeTypeList = this.academicPogramLevelData.map(
              (item) => item
            );
            if(this.param2){

                for(let i=0;i<this.filterdDegreeTypeList.length;i++){

                  if(this.param2==this.filterdDegreeTypeList[i].id){
                    this.programListPreSelect=i;
                    this.academicDetails.get('programList').setValue(this.filterdDegreeTypeList[i]);
                    break;
                  }
                }
            }
            break;
          }
          case 'North Housten (Greenspoint, TX)':{
            this.academicPogramLevelData = this.academicDegreeData.filter(
              (item) => (item.id == 10843 || item.id == 8122 || item.id == 8123 || item.id == 8125 || item.id == 8144 || item.id == 9875 || item.id == 8115 || item.id == 8128 || item.id == 9130)
            );
            this.filterdDegreeTypeList = this.academicPogramLevelData.map(
              (item) => item
            );
            if(this.param2){

                for(let i=0;i<this.filterdDegreeTypeList.length;i++){

                  if(this.param2==this.filterdDegreeTypeList[i].id){
                    this.programListPreSelect=i;
                    this.academicDetails.get('programList').setValue(this.filterdDegreeTypeList[i]);
                    break;
                  }
                }
            }
            break;
          }
          case 'Housten Southest,TX':{
            this.academicPogramLevelData = this.academicDegreeData.filter(
              (item) => (item.id == 8122 || item.id == 8123 || item.id == 9875 || item.id == 8128)
            );
            this.filterdDegreeTypeList = this.academicPogramLevelData.map(
              (item) => item
            );
            if(this.param2){

                for(let i=0;i<this.filterdDegreeTypeList.length;i++){

                  if(this.param2==this.filterdDegreeTypeList[i].id){
                    this.programListPreSelect=i;
                    this.academicDetails.get('programList').setValue(this.filterdDegreeTypeList[i]);
                    break;
                  }
                }
            }
            break;
          }
          case 'Knoxville, TN':{
            this.academicPogramLevelData = this.academicDegreeData.filter(
              (item) => (item.id == 10843 || item.id == 10844 || item.id == 8125 || item.id == 9875 || item.id == 9255)
            );
            this.filterdDegreeTypeList = this.academicPogramLevelData.map(
              (item) => item
            );
            if(this.param2){

                for(let i=0;i<this.filterdDegreeTypeList.length;i++){

                  if(this.param2==this.filterdDegreeTypeList[i].id){
                    this.programListPreSelect=i;
                    this.academicDetails.get('programList').setValue(this.filterdDegreeTypeList[i]);
                    break;
                  }
                }
            }
            break;
          }
          case 'Lafayette, LA':{
            this.academicPogramLevelData = this.academicDegreeData.filter(
              (item) => (item.id == 10843 || item.id == 9815 || item.id == 8253 || item.id == 8125 || item.id == 8144 || item.id == 9875 || item.id == 9130 || item.id == 8118 || item.id == 8145 || item.id == 9632)
            );
            this.filterdDegreeTypeList = this.academicPogramLevelData.map(
              (item) => item
            );
            if(this.param2){

                for(let i=0;i<this.filterdDegreeTypeList.length;i++){

                  if(this.param2==this.filterdDegreeTypeList[i].id){
                    this.programListPreSelect=i;
                    this.academicDetails.get('programList').setValue(this.filterdDegreeTypeList[i]);
                    break;
                  }
                }
            }
            break;
          }
          case 'Memphis, TN':{
            this.academicPogramLevelData = this.academicDegreeData.filter(
              (item) => (item.id == 10843 || item.id == 10844 || item.id == 9631 || item.id == 9601 || item.id == 8110 || item.id == 8253 || item.id == 8125 || item.id == 8144 || item.id == 9875 || item.id == 9130 || item.id == 9255 || item.id == 9632)
            );
            this.filterdDegreeTypeList = this.academicPogramLevelData.map(
              (item) => item
            );
            if(this.param2){

                for(let i=0;i<this.filterdDegreeTypeList.length;i++){

                  if(this.param2==this.filterdDegreeTypeList[i].id){
                    this.programListPreSelect=i;
                    this.academicDetails.get('programList').setValue(this.filterdDegreeTypeList[i]);
                    break;
                  }
                }
            }
            break;
          }
          case 'Mobile, AL':{
            this.academicPogramLevelData = this.academicDegreeData.filter(
              (item) => (item.id == 10843 || item.id == 8122 || item.id == 8253 || item.id == 8125 || item.id == 8115 || item.id == 9875 || item.id == 9130 || item.id == 8129 || item.id == 8120)
            );
            this.filterdDegreeTypeList = this.academicPogramLevelData.map(
              (item) => item
            );
            if(this.param2){

                for(let i=0;i<this.filterdDegreeTypeList.length;i++){

                  if(this.param2==this.filterdDegreeTypeList[i].id){
                    this.programListPreSelect=i;
                    this.academicDetails.get('programList').setValue(this.filterdDegreeTypeList[i]);
                    break;
                  }
                }
            }
            break;
          }
          case 'Nashville, TN':{
            this.academicPogramLevelData = this.academicDegreeData.filter(
              (item) => (item.id == 9874 || item.id == 8099 || item.id == 9072 || item.id == 9073 || item.id == 9074 || item.id == 10843 || item.id == 10844 || item.id == 9815 || item.id == 8123 || item.id == 8109 || item.id == 8125 || item.id == 9875 || item.id == 9130 || item.id == 9255 || item.id == 9632)
            );
            this.filterdDegreeTypeList = this.academicPogramLevelData.map(
              (item) => item
            );
            if(this.param2){

                for(let i=0;i<this.filterdDegreeTypeList.length;i++){

                  if(this.param2==this.filterdDegreeTypeList[i].id){
                    this.programListPreSelect=i;
                    this.academicDetails.get('programList').setValue(this.filterdDegreeTypeList[i]);
                    break;
                  }
                }
            }
            break;
          }
          case 'Shreveport, LA':{
            this.academicPogramLevelData = this.academicDegreeData.filter(
              (item) => (item.id == 9072 || item.id == 9073 || item.id == 9074 || item.id == 8146 || item.id == 8124 || item.id == 8144 || item.id == 8125 || item.id == 9875 || item.id == 9130 || item.id == 9258 || item.id == 8118 || item.id == 8145 || item.id == 9632)
            );
            this.filterdDegreeTypeList = this.academicPogramLevelData.map(
              (item) => item
            );
            if(this.param2){

                for(let i=0;i<this.filterdDegreeTypeList.length;i++){

                  if(this.param2==this.filterdDegreeTypeList[i].id){
                    this.programListPreSelect=i;
                    this.academicDetails.get('programList').setValue(this.filterdDegreeTypeList[i]);
                    break;
                  }
                }
            }
            break;
          }

        }
      });
    }
    else if(this.param1==2256){
      this.checkbtn0=true;
      this.onlinePre=true;
      this.academicDetails.get('degreeType').setValue('onlineDegree');
      let academicDegreeData2=[{name:`Bachelor`,src:'assets/images/bachelors.svg'},{name:`Associate`,src:'assets/images/associates.png'},{name:`Diploma`,src:'assets/images/diploma.png'}]
  // get filterd degreeLevel data
      this.academicDegreeLevelData = academicDegreeData2.map(
        (degreeLvel) => degreeLvel
      );
    }
    setTimeout(() => {
      if(this.checkValid()) this.academicInterestSubmit() 
    }, 1000);

  }
  calculateFormProgress(form: FormGroup): number {
    const controlCount = Object.keys(form.controls).length;
    let validCount = 0;
    for (const [key, value] of Object.entries(form.controls)) {
      if (value.value != "") validCount++;
    }
    return ((validCount * 0.34) / 45) * 100;
  }
  // // Init DualEnrollment
  // initializeDualEnrollment(): FormGroup {
  //   return this.academicDetailsBuilder.group({
  //     dualEnrollmentCollegeNameAttended: new FormControl(''),
  //     dualEnrollmentLastYearAttended: new FormControl(''),
  //     dualEnrollmentHighSchoolCountry: new FormControl(''),
  //     degreeLevelReceivedFromSchoolAbove: new FormControl(''),

  //   })
  // }

  // get enrollment field value
  dualEnrollmentCollegeDetailsValued: any;
  dualEnrolActive: Boolean = true;
  getdualEnrollmentCollegeDetails(getIndex: number) {
    this.dualEnrollmentCollegeDetailsValued = this.academicDetails.get(
      "dualEnrollmentCollegeDetails"
    );
    if ((this.dualEnrollmentCollegeDetailsValued.length = 3)) {
      this.dualEnrollmentCollegeDetails.removeAt(0);
      this.dualEnrolActive = !this.dualEnrolActive;
    } else {
      this.dualEnrolActive = true;
    }
  }

  // get enrollment field control
  get dualEnrollmentCollegeDetaisCValue() {
    return this.academicDetails.get(
      "dualEnrollmentCollegeDetails"
    ) as FormControl;
  }

  dualEnrollmentCollegeDetailsValue: any[] = null;
  addDualEnrollmentColleges(): void {
    this.dualEnrollmentCollegeDetailsValue =
      this.academicDetails.controls.dualEnrollmentCollegeDetails.value;
    this.dualEnrollmentCollegeDetails = this.academicDetails.get(
      "dualEnrollmentCollegeDetails"
    ) as FormArray;
    // this.dualEnrollmentCollegeDetails.push(this.initializeDualEnrollment());
    for (let i = 0; i < this.dualEnrollmentCollegeDetailsValue.length; i++) {}
  }

  // filter programs by searching
  filterProgramString = "";
  filterChanged(filterText: string) {
    this.filterProgramString = filterText;
    if (filterText) {
      this.academicAccordion.openAll();
    } else {
      this.academicAccordion.closeAll();
    }
  }

  // get programs from degreeTypeList
  getProgramsList(itemName) {
    return this.academicPogramLevelData
      .filter(
        (item) =>
          item.title
            .toLowerCase()
            .indexOf(this.filterProgramString.toLowerCase()) > -1
      )
      .filter((item) => item["careers"][0].title === itemName);
  }

  // filterd program List
  filterdDegreeTypeList = [];
  getProgramLevel(DegreeTitleValue) {
    let d=document.getElementById('info3');
    d.scrollIntoView({behavior:"smooth"});
    this.academicDetails.get('programList').setValue(null);
    this.selectedDegreeTitle = DegreeTitleValue;
    this.academicPogramLevelData = this.academicDegreeData.filter(
      (item) => item.degree_type === this.selectedDegreeTitle
    );
    this.filterdDegreeTypeList = this.academicPogramLevelData.map(
      (item) => item
    );
    if(this.selectedDegreeTitle=='Bachelor'){
      this.filterdDegreeTypeList=[{title:"Criminal Justice",id:1001},{title:"Organizational Management",id:1002}]
    }
    else if(this.selectedDegreeTitle=='Diploma'){
      this.filterdDegreeTypeList.push({title:"Beat Production and Recording Art Technology",id:2001},{title:"Heating Ventilation and Air Conditioning",id:2002});
    }
    else if(this.selectedDegreeTitle=='Associate'){
      this.filterdDegreeTypeList.push({title:"Digital Graphic Art",id:3001},{title:"Heating Ventilation and Air Conditioning",id:3002});
    }
    this.filterdDegreeTypeList = this.removeDuplicates(
      this.filterdDegreeTypeList
    );
    this.filterdDegreeTypeList=this.filterdDegreeTypeList.sort();
  }

  public getLocationPrograms(loc){
    let d=document.getElementById('info');
    d.scrollIntoView({behavior:"smooth"});
    this.programListPreSelect=null;
    this.academicDetails.get('programList').setValue(null);
    switch(loc){
      case 'Baton Rouge, LA':{
        this.academicPogramLevelData = this.academicDegreeData.filter(
          (item) => (item.id == 8125 || item.id == 8120 || item.id == 9875)
        );
        this.filterdDegreeTypeList = this.academicPogramLevelData.map(
          (item) => item
        );
        break;
      }
      case 'Cleveland, OH':{
        this.academicPogramLevelData = this.academicDegreeData.filter(
          (item) => (item.id == 10843 || item.id == 8123 || item.id == 9875 || item.id == 9130)
        );
        this.filterdDegreeTypeList = this.academicPogramLevelData.map(
          (item) => item
        );
        break;
      }
      case 'Dallas, TX(Garland)':{
        this.academicPogramLevelData = this.academicDegreeData.filter(
          (item) => (item.id == 10843 || item.id == 8099 || item.id == 8146 || item.id == 8253 || item.id == 8125 || item.id == 9875 || item.id == 8115 || item.id == 8128 || item.id == 9258)
        );
        this.filterdDegreeTypeList = this.academicPogramLevelData.map(
          (item) => item
        );
        break;
      }
      case 'Fort Worth, TX':{
        this.academicPogramLevelData = this.academicDegreeData.filter(
          (item) => (item.id == 10843 || item.id == 9815 || item.id == 8123 || item.id == 8253 || item.id == 8144 || item.id == 9875 || item.id == 8115 || item.id == 8128 || item.id == 9130 || item.id == 9255)
        );
        this.filterdDegreeTypeList = this.academicPogramLevelData.map(
          (item) => item
        );
        break;
      }
      case 'North Housten (Greenspoint, TX)':{
        this.academicPogramLevelData = this.academicDegreeData.filter(
          (item) => (item.id == 10843 || item.id == 8122 || item.id == 8123 || item.id == 8125 || item.id == 8144 || item.id == 9875 || item.id == 8115 || item.id == 8128 || item.id == 9130)
        );
        this.filterdDegreeTypeList = this.academicPogramLevelData.map(
          (item) => item
        );
        break;
      }
      case 'Housten Southest,TX':{
        this.academicPogramLevelData = this.academicDegreeData.filter(
          (item) => (item.id == 8122 || item.id == 8123 || item.id == 9875 || item.id == 8128)
        );
        this.filterdDegreeTypeList = this.academicPogramLevelData.map(
          (item) => item
        );
        break;
      }
      case 'Knoxville, TN':{
        this.academicPogramLevelData = this.academicDegreeData.filter(
          (item) => (item.id == 10843 || item.id == 10844 || item.id == 8125 || item.id == 9875 || item.id == 9255)
        );
        this.filterdDegreeTypeList = this.academicPogramLevelData.map(
          (item) => item
        );
        break;
      }
      case 'Lafayette, LA':{
        this.academicPogramLevelData = this.academicDegreeData.filter(
          (item) => (item.id == 10843 || item.id == 9815 || item.id == 8253 || item.id == 8125 || item.id == 8144 || item.id == 9875 || item.id == 9130 || item.id == 8118 || item.id == 8145 || item.id == 9632)
        );
        this.filterdDegreeTypeList = this.academicPogramLevelData.map(
          (item) => item
        );
        break;
      }
      case 'Memphis, TN':{
        this.academicPogramLevelData = this.academicDegreeData.filter(
          (item) => (item.id == 10843 || item.id == 10844 || item.id == 9631 || item.id == 9601 || item.id == 8110 || item.id == 8253 || item.id == 8125 || item.id == 8144 || item.id == 9875 || item.id == 9130 || item.id == 9255 || item.id == 9632)
        );
        this.filterdDegreeTypeList = this.academicPogramLevelData.map(
          (item) => item
        );
        break;
      }
      case 'Mobile, AL':{
        this.academicPogramLevelData = this.academicDegreeData.filter(
          (item) => (item.id == 10843 || item.id == 8122 || item.id == 8253 || item.id == 8125 || item.id == 8115 || item.id == 9875 || item.id == 9130 || item.id == 8129 || item.id == 8120)
        );
        this.filterdDegreeTypeList = this.academicPogramLevelData.map(
          (item) => item
        );
        break;
      }
      case 'Nashville, TN':{
        this.academicPogramLevelData = this.academicDegreeData.filter(
          (item) => (item.id == 9874 || item.id == 8099 || item.id == 9072 || item.id == 9073 || item.id == 9074 || item.id == 10843 || item.id == 10844 || item.id == 9815 || item.id == 8123 || item.id == 8109 || item.id == 8125 || item.id == 9875 || item.id == 9130 || item.id == 9255 || item.id == 9632)
        );
        this.filterdDegreeTypeList = this.academicPogramLevelData.map(
          (item) => item
        );
        break;
      }
      case 'Shreveport, LA':{
        this.academicPogramLevelData = this.academicDegreeData.filter(
          (item) => (item.id == 9072 || item.id == 9073 || item.id == 9074 || item.id == 8146 || item.id == 8124 || item.id == 8144 || item.id == 8125 || item.id == 9875 || item.id == 9130 || item.id == 9258 || item.id == 8118 || item.id == 8145 || item.id == 9632)
        );
        this.filterdDegreeTypeList = this.academicPogramLevelData.map(
          (item) => item
        );
        break;
      }

    }

    this.filterdDegreeTypeList=this.filterdDegreeTypeList.sort();
  }

  locationProgramSelected(ev,value){
    let d=document.getElementById('info');
    d.scrollIntoView({behavior:"smooth"});
    (ev.target as HTMLElement).style.background='lightgrey';
    this.academicDetails.get('programList').setValue(value);

  }
  goDown(){
    let d=document.getElementById('degLevel');
    d.scrollIntoView({behavior:"smooth"});
  }
  // get selected program from degree type
  selectedDegreeTypeTitle: string;
  getSelecedProgram(selectedProgram: any) {
    let d=document.getElementById('degLevel');
    d.scrollIntoView({behavior:"smooth"});
    this.selectedProgram = selectedProgram;
    this.selectedDegreeTypeTitle = this.selectedProgram.careers[0].title;
  }

  // show Master based on the degree type slected
  showSchollDiploma() {
    if (
      this.selectedDegreeTitle === "Bachelor's Degree" ||
      this.selectedDegreeTitle === "Graduate Certificate" ||
      this.selectedDegreeTitle === "Undergraduate Certificate" ||
      this.selectedDegreeTitle === "Associate's Degree"
    ) {
      return true;
    } else {
      return false;
    }
  }

  // show Master based on the degree type slected
  showMasterFields() {
    if (this.selectedDegreeTitle === "Master's Degree") {
      return true;
    } else {
      return false;
    }
  }

  // show Doctorate based on the degree type slected
  showDoctorateFields() {
    if (this.selectedDegreeTitle === "Doctorate") {
      return true;
    } else {
      return false;
    }
  }

  // edit degree type
  editDegreeType() {
    this.editForm = true
    this.updateFormStateOnEdit();

    // remove previous data from service
    this.registerService.updateData(this.academicFormData);
    this.isAcademicViewMode = false;
    this._connectionInterestService.viewMode[0]=false;
  }

  // edit degree level
  editDegreeLevel() {
    this.academicDetails.get("degreeLevel").reset();
    this.academicDetails.get("programList").reset();
    // when edit after Academic Form submitted
    this.updateFormStateOnEdit();

    // remove previous data from service
    this.updateFormData();
  }

  // edit selected program
  editSelectedProgram() {
    this.academicDetails.get("programList").reset();
    // when edit after Academic Form submitted
    this.updateFormStateOnEdit();

    // remove previous data from service
    this.updateFormData();
  }

  // remove enrollment field
  removeDualEnrollmentColleges(i: number) {
    this.dualEnrollmentCollegeDetails = this.academicDetails.get(
      "dualEnrollmentCollegeDetails"
    ) as FormArray;
    this.dualEnrollmentCollegeDetails.removeAt(i);
  }

  academicInterestFormData = { formValue: {} };

  // Submit Academic Form
  academicInterestSubmit() {
    if (this.checkValid()) {
      this.updateFormStateOnSubmit();
      // window.scrollTo({ top: 120, left: 0, behavior: "smooth" });
      // let d=document.getElementById('preEducation');
      // d.scrollIntoView({behavior:"smooth"});
      window.scrollTo({ top: 220, left: 0, behavior: "smooth" });
      // get form data
      this.updateFormData();
      this.academicInterestFormData = {
        formValue: this.academicDetails.value,
        // selectedItem: this.selectedProgram,
      };
      this.registerService.pushData(this.academicInterestFormData);
      this.isAcademicViewMode = true;
      this._connectionInterestService.viewMode[0]=true;
    } else
      this.isAcademicViewMode = false;
  }

  // update form data when edit form fields
  academicFormData;
  updateFormData() {
    this.academicFormData = this.academicDetails.value;
    this.registerService.updateData(this.academicInterestFormData);
  }

  // update form active state for other forms when editing current form
  updateFormStateOnEdit(){
    let fildData = this._connectionInterestService.getValueChange()
    fildData[0]=true
    this._connectionInterestService.fldShowValueChange(fildData);
  }

  // update form active state for other forms when sumiting current form
  updateFormStateOnSubmit() {
    let fildData = this._connectionInterestService.getValueChange()
    fildData[0]=false
    if(!this._connectionInterestService.viewMode[1])
    fildData[1]=true
    this._connectionInterestService.fldShowValueChange(fildData);
  }
  checkValid(){
    if(this.academicDetails.value.degreeType == 'residentialDegree' && this.academicDetails.value.degreeLocation && this.academicDetails.value.programList)
    return true
    else if(this.academicDetails.value.degreeType == 'onlineDegree' && this.academicDetails.value.degreeLevel && this.academicDetails.value.programList)
    return true;
    else return false;
  }
}
