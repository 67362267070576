import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './material.module';
import { CommonModule } from '@angular/common';
import { PersonalInformationComponent, personalDetailsNotification } from 'src/app/personal-information/personal-information.component';
import { AcademicInterestionComponent } from 'src/app/academic-interestion/academic-interestion.component';
import { AgreementAcceptanceComponent, InstitutionalTermsConditionsDialog, InstitutionalTermsConditionsNotification } from 'src/app/agreement-acceptance/agreement-acceptance.component';

// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { MatSelectSearchModule } from 'src/app/mat-select-search/mat-select-search.module';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { connectionInterestService } from 'src/app/service/connection.service';
import { SharedModule } from './shared/shared.module';
import { PreviousEducationComponent } from './previous-education/previous-education.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    AppComponent,
    PersonalInformationComponent,
    AcademicInterestionComponent,
    AgreementAcceptanceComponent,
    personalDetailsNotification,
    InstitutionalTermsConditionsDialog,
    InstitutionalTermsConditionsNotification,
    PreviousEducationComponent,
    ThankyouComponent
  ],
  imports: [
    BrowserModule,
    MatSelectSearchModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    MatIconModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    
    // NgxIntlTelInputModule,
    // Ng2SearchPipeModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,    
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      countDuplicates: false,
      closeButton: true
  }),
  ],
  providers: [
    connectionInterestService, 
    { 
      /* Global injection token for form field hint or error text element.By default, MatFormField still reserves exactly one line of space below the field for hint or error text.This is the value we want to override. */
       provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
      subscriptSizing: 'dynamic'
      }
    }
  ],
  bootstrap: [AppComponent],
  // entryComponents: [
  //   personalDetailsNotification, InstitutionalTermsConditionsDialog, InstitutionalTermsConditionsNotification
  // ]
})
export class AppModule { }
