import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

export interface userData {
  peronalData: object,
  acdemicData: Array<any>,
  agreementData: object
}

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  _url: string = 'http://localhost:4200';

  private userData: any[] = []
  userDataSource: BehaviorSubject<Array<any>> = new BehaviorSubject([]);
  userDataObserver = this.userDataSource.asObservable();

  constructor(private http: HttpClient) { }

  // add new data 
  pushData(data) {
    const currentValue = this.userDataSource.value;
    const updatedValue = [...currentValue, data];

    this.userData = updatedValue;
    this.userDataSource.next(this.userData);
  }

  // update with current data
  updateData(data) {
    this.userData = this.userDataSource.value.filter((item => item !== data));
    this.userDataSource.next(this.userData);
  }

  getData() {
    return this.userDataObserver;
  }

  // final form data
  register(userData) {
    return this.http.post<any>(this._url, userData);
  }
}
