import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewChecked,
  HostListener
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
  FormArray,
} from "@angular/forms";
import {
  trigger,
  state,
  animate,
  transition,
  style,
} from "@angular/animations";
import { connectionInterestService } from "../service/connection.service";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { RegisterService } from "../service/register.service";
import { ApiService } from "src/app/service/api.service";

@Component({
  selector: "app-previous-education",
  templateUrl: "./previous-education.component.html",
  styleUrls: ["./previous-education.component.scss"],
  animations: [
    trigger("height4sAnim", [
      state("true", style({ opacity: 1, height: "auto" })),
      state("void", style({ opacity: 0, height: 0 })),
      transition("* => *", [animate("0.4s")]),
    ]),
    trigger("btnStep3sAnim", [
      state("true", style({ "margin-left": 0, opacity: 0 })),
      state("void", style({ opacity: 1, "margin-left": -280, "z-index": 99 })),
      transition("* => *", [animate("0.3s")]),
    ]),
    trigger("height2sAnim", [
      state("true", style({ opacity: 1, height: "auto" })),
      state("void", style({ opacity: 0, height: 0 })),
      transition("* => *", [animate("0.2s")]),
    ]),
    trigger("balloonEffect", [
      state(
        "true",
        style({ backgroundColor: "green", transform: "scale(1.5)" })
      ),
      state("void", style({ backgroundColor: "red", transform: "scale(1)" })),
      transition("* => *", [animate("2s")]),
    ]),
  ],
})
export class PreviousEducationComponent implements OnInit, AfterViewChecked {
  fildShowvalue: boolean[];
  formSubmited: boolean;
  previousEducation: FormGroup;
  selectedDegreeTitle: any;
  academicPogramLevelData = [];
  selectedProgram: any;
  dualEnrollmentCollegeDetails: FormArray;
  display: any = false;
  academicInterest: any;
  isSearchData: boolean = true;
  isCollegeSearch: boolean = true;
  searchInput: any;
  searchCollegeInput: any;
  searchInputValue: any;
  previousEduEditData: any = null;
  selectedDegreeType:any;
  editMode:boolean = false
  graduationMonth = [
    "01 - January",
    "02 - February",
    "03 - March",
    "04 - April",
    "05 - May",
    "06 - June",
    "07 - July",
    "08 - August",
    "09 - September",
    "10 - October",
    "11 - November",
    "12 - December",
  ];

  graduationYear = [
    "2022",
    "2021",
    "2020",
    "2019",
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
    "2007",
    "2006",
    "2005",
    "2004",
    "2003",
    "2002",
    "2001",
    "2000",
    "1999",
    "1998",
    "1997",
    "1996",
    "1995",
    "1994",
    "1993",
    "1992",
    "1991",
    "1990",
    "1989",
    "1987",
    "1986",
    "1985",
    "1984",
    "1983",
  ];
  highSchoolDiplomaList = [
    {type:'highSchool', id:1276,name:'High School' },
    {type:'homeSchool', id:1286,name:'Home School'},
    {type:'ged', id:1277, name:'GED'}
  ]
  schoolList: string[] = [];

  highSchoolList: any[] = [];

  selectedSchool: any[] = [];
  selectedCollege: any[];
  completed:any=false;
  isPreviousEducationViewMode: any = false;

  public progress: number;
  public formObserver: Subscription;

  @Output() progressBarData = new EventEmitter<any>();
  collegesSearchChar: number;
  collegeSearchChar: number;

  constructor(
    private previousEducationBuilder: FormBuilder,
    public _connectionInterestService: connectionInterestService,
    private registerService: RegisterService,
    private cdRef: ChangeDetectorRef,
    private apiService: ApiService,

  ) { }
  public highSchoolControl = new FormControl("");
  public homeSchoolControl = new FormControl("");
  public collegeControl = new FormControl("");
  public validation_msgs = {
    'highSchoolControl': [
      { type: 'required', message: 'Name of High School is required.' }
    ],
    'homeSchoolControl': [
      { type: 'required', message: 'Name of Home School is required.' }
    ],
    'collegeControl':[
      { type: 'required', message: 'Name of College is required.' }
    ]
  }
  ngOnInit() {
    this.highSchoolControl.valueChanges.subscribe(data=>{
      this.previousEducation.get('highSchool').setValue(data);
    })
    this.homeSchoolControl.valueChanges.subscribe(data=>{
      this.previousEducation.get('homeSchool').setValue(data);
    })
    this.collegeControl.valueChanges.subscribe(data=>{
      this.previousEducation.get('college').setValue(data);
    })
    this._connectionInterestService.FildShowValue.subscribe(
      (fildShowvalue) => (this.fildShowvalue = fildShowvalue)
    );
    this._connectionInterestService.formSubmited.subscribe(
      (formSubmited) => (this.formSubmited = formSubmited)
    );

    this.previousEducation = this.previousEducationBuilder.group({
      highSchoolDiploma: new FormControl(""),
      highSchoolCountryName: new FormControl(""),
      highSchoolFirstName: new FormControl(""),
      highSchoolLastName: new FormControl(""),
      dualEnrollmentPreviously: new FormControl(""),
      attendedCollegesUniversities: new FormControl(""),
      officialTranscripts: new FormControl(""),
      highSchoolDiplomaOrGEDPriorTEnrolling: new FormControl(""),
      expelledOrDismissedEducationalInstitution: new FormControl(""),
      homeSchoolFirstName: new FormControl(""),
      homeSchoolLastName: new FormControl(""),
      GEDLastName: new FormControl(""),
      GEDFirstName: new FormControl(""),
      GEDAwardState: new FormControl(""),
      FAACommercialPilotCertificate: new FormControl(""),
      FAAAirframePowerplantCertificate: new FormControl(""),
      additionalFlightTraining: new FormControl(""),
      whereWillCompleteFlightTraining: new FormControl(""),
      whichWillCompleteFlightTraining: new FormControl(""),
      dualEnrollmentHighSchoolState: new FormControl(""),
      dualEnrollmentCollegeDetails: this.previousEducationBuilder.array([
        this.initializeDualEnrollment(),
      ]),
      bachelorSchoolName: new FormControl(""),
      bachelorSchoolGraduationDate: new FormControl(""),
      doctorateSchoolName: new FormControl(""),
      doctorateGraduationDate: new FormControl(""),
      graduationMonth: new FormControl(""),
      graduationYear: new FormControl(""),
      priorStandardCertificate: new FormControl(""),
      finalCollegeMark: new FormControl(""),
      finalCollegeList: new FormControl(""),
      homeSchool: new FormControl(""),
      homeSchoolGraduationMonth: new FormControl(""),
      homeSchoolGraduationYear: new FormControl(""),
      highSchool: new FormControl("",Validators.required),
      highSchoolGraduationMonth: new FormControl(""),
      highSchoolGraduationYear: new FormControl(""),
      college: new FormControl(""),
      collegeGraduationMonth: new FormControl(""),
      collegeGraduationYear: new FormControl(""),
      gedGraduationMonth: new FormControl(""),
      gedGraduationYear: new FormControl(""),
      collegeSearch: new FormControl(""),
      graduateInstitution: new FormControl(""),
      highSchoolSearch: new FormControl(""),
      oweBalanceSchool: new FormControl(""),
      expelledInstitution: new FormControl(""),
    });

    this.formObserver = this.previousEducation.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => this.onFormChanged(this.previousEducation));

    this.previousEducation.get("finalCollegeList").valueChanges.subscribe(val=>{
      if(val == 'no'){
        this.previousEducation.patchValue({
          collegeControl:null,
          college:null,
          graduateInstitution:null
        })
      }
    })

    // service data
    this.registerService.getData().subscribe((data) => {
      this.academicInterest = data;
      if (!this.academicInterest[0]) return;
      this.selectedProgram = this.academicInterest[0].selectedItem;
      // this.selectedDegreeTitle = this.academicInterest[0].formValue.degreeLevel;
      // this.selectedDegreeType=this.academicInterest[0].formValue.degreeType;
    });
    if(!this.editMode){
      this.previousEducation.patchValue({
        priorStandardCertificate:'yes',
        finalCollegeList:'no',
        graduateInstitution:'no'
      })
    }
  }
  ngAfterViewChecked() {

    this.dualEnrollmentChangeValue.valueChanges.subscribe((checked) => {
      let groupItems: any = (
        this.previousEducation.get("dualEnrollmentCollegeDetails") as FormArray
      ).controls;
      if (checked == "yes") {
        for (let item of groupItems) {
          item.controls["dualEnrollmentCollegeNameAttended"].setValidators([
            Validators.required,
          ]);
          item.controls["dualEnrollmentLastYearAttended"].setValidators([
            Validators.required,
          ]);
          item.controls["dualEnrollmentHighSchoolCountry"].setValidators([
            Validators.required,
          ]);
          item.controls["degreeLevelReceivedFromSchoolAbove"].setValidators([
            Validators.required,
          ]);
          this.cdRef.detectChanges();
        }
      } else {
        for (let item of groupItems) {
          item.controls["dualEnrollmentCollegeNameAttended"].setValidators(
            null
          );
          item.controls["dualEnrollmentLastYearAttended"].setValidators(null);
          item.controls["dualEnrollmentHighSchoolCountry"].setValidators(null);
          item.controls["degreeLevelReceivedFromSchoolAbove"].setValidators(
            null
          );
        }
      }
    });
  }

  // get form controls
  get dualEnrollmentChangeValue() {
    return this.previousEducation.get(
      "dualEnrollmentPreviously"
    ) as FormControl;
  }



  onFormChanged(form: FormGroup): void {
    this.progress = this.calculateFormProgress(form);
    this.progressBarData.emit(this.progress);
  }

  calculateFormProgress(form: FormGroup): number {
    const controlCount = Object.keys(form.controls).length;
    let validCount = 0;
    for (const [key, value] of Object.entries(form.controls)) {
      if (value.value != "") validCount++;
    }
    return ((validCount * 0.34) / 45) * 100;
  }

  // Init DualEnrollment
  initializeDualEnrollment(): FormGroup {
    return this.previousEducationBuilder.group({
      dualEnrollmentCollegeNameAttended: new FormControl(""),
      dualEnrollmentLastYearAttended: new FormControl(""),
      dualEnrollmentHighSchoolCountry: new FormControl(""),
      degreeLevelReceivedFromSchoolAbove: new FormControl(""),
    });
  }
  highSchoolvalidation=true;
  // show Master based on the degree type slected
  showSchollDiploma() {
    if (
      this.selectedDegreeTitle === "Bachelor's Degree" ||
      this.selectedDegreeTitle === "Bachelor" ||
      this.selectedDegreeTitle === "Graduate Certificate" ||
      this.selectedDegreeTitle === "Undergraduate Certificate" ||
      this.selectedDegreeTitle === "Associate's Degree" ||
      this.selectedDegreeTitle === "Associate" ||
      this.selectedDegreeTitle === "Diploma" ||
      this.selectedDegreeType === "residentialDegree"

    ) {
      this.highSchoolvalidation=false;
      this.previousEducation.controls["highSchoolDiploma"].setValidators(Validators.required);
      return true;

    } else {
      return false;
    }
  }


  educationFormData:any;
  // Submit Academic Form
  previousEducationSubmit() {
      this.completed=true;
      this.previousEduEditData = this.previousEducation.value;
      this._connectionInterestService.viewMode[1]=true;
      this._connectionInterestService.viewMode[0]=true;
      this.updateFormStateOnSubmit();


      // let d=document.getElementById('personalInfo');
      // d.scrollIntoView({behavior:"smooth"});
      window.scrollTo({ top: 400, left: 0, behavior: "smooth" });
      // get form data
      this.updateFormData();
      console.log(this.previousEducation.value);
      
      this.educationFormData ={
        level_of_education:this.previousEducation.value.highSchoolDiploma,
        NameOfSchool:this.previousEducation.value.highSchoolDiploma.type == 'homeSchool' ? this.previousEducation.value.homeSchool : this.previousEducation.value.highSchoolDiploma.type == 'ged' ? this.previousEducation.value.GEDAwardState : this.previousEducation.value.highSchoolDiploma.type == 'highSchool' ? this.previousEducation.value.highSchool :'',
        graduationYear: this.previousEducation.value.highSchoolDiploma.type == 'homeSchool' ? this.previousEducation.value.homeSchoolGraduationYear : this.previousEducation.value.highSchoolDiploma.type == 'ged' ? this.previousEducation.value.gedGraduationYear : this.previousEducation.value.highSchoolDiploma.type == 'highSchool' ? this.previousEducation.value.highSchoolGraduationYear :'',
        graduationMonth: this.previousEducation.value.highSchoolDiploma.type == 'homeSchool' ? this.previousEducation.value.homeSchoolGraduationMonth : this.previousEducation.value.highSchoolDiploma.type == 'ged' ? this.previousEducation.value.gedGraduationMonth : this.previousEducation.value.highSchoolDiploma.type == 'highSchool' ? this.previousEducation.value.highSchoolGraduationMonth :'',
        first_name_diploma: this.previousEducation.value.highSchoolDiploma.type == 'homeSchool' ? this.previousEducation.value.homeSchoolFirstName : this.previousEducation.value.highSchoolDiploma.type == 'ged' ? this.previousEducation.value.GEDFirstName : this.previousEducation.value.highSchoolDiploma.type == 'highSchool' ? this.previousEducation.value.highSchoolFirstName :'',
        last_name_diploma: this.previousEducation.value.highSchoolDiploma.type == 'homeSchool' ? this.previousEducation.value.homeSchoolLastName : this.previousEducation.value.highSchoolDiploma.type == 'ged' ? this.previousEducation.value.GEDLastName : this.previousEducation.value.highSchoolDiploma.type == 'highSchool' ? this.previousEducation.value.highSchoolLastName :'',
        priorStandardCertificate: this.previousEducation.value.priorStandardCertificate,
        finalCollegeList:this.previousEducation.value.finalCollegeList,
        collegeControl:this.previousEducation.value.collegeControl,
        graduateInstitution:this.previousEducation.value.graduateInstitution,
        collegeGraduationMonth:this.previousEducation.value.collegeGraduationMonth,
        collegeGraduationYear:this.previousEducation.value.collegeGraduationYear,
      } 
      
      this.registerService.pushData(this.educationFormData);
  }

  // update form data when edit form fields
  previousEducationFormData;
  updateFormData() {
    this.previousEducationFormData = this.previousEducation.value;
    this.registerService.updateData(this.educationFormData);
  }

  // show Master based on the degree type slected
  showMasterFields() {
    if (this.selectedDegreeTitle === "Master's Degree") {
      return true;
    } else {
      return false;
    }
  }

  // show Doctorate based on the degree type slected
  showDoctorateFields() {
    if (this.selectedDegreeTitle === "Doctorate") {
      return true;
    } else {
      return false;
    }
  }

  // remove enrollment field
  removeDualEnrollmentColleges(i: number) {
    this.dualEnrollmentCollegeDetails = this.previousEducation.get(
      "dualEnrollmentCollegeDetails"
    ) as FormArray;
    this.dualEnrollmentCollegeDetails.removeAt(i);
  }

  // get enrollment field value
  dualEnrollmentCollegeDetailsValued: any;
  dualEnrolActive: Boolean = true;
  getdualEnrollmentCollegeDetails(getIndex: number) {
    this.dualEnrollmentCollegeDetailsValued = this.previousEducation.get(
      "dualEnrollmentCollegeDetails"
    );
    if ((this.dualEnrollmentCollegeDetailsValued.length = 3)) {
      this.dualEnrollmentCollegeDetails.removeAt(0);
      this.dualEnrolActive = !this.dualEnrolActive;
    } else {
      this.dualEnrolActive = true;
    }
  }

  // get enrollment field control
  get dualEnrollmentCollegeDetaisCValue() {
    return this.previousEducation.get(
      "dualEnrollmentCollegeDetails"
    ) as FormControl;
  }

  dualEnrollmentCollegeDetailsValue: any[] = null;
  addDualEnrollmentColleges(): void {
    this.dualEnrollmentCollegeDetailsValue =
      this.previousEducation.controls.dualEnrollmentCollegeDetails.value;
    this.dualEnrollmentCollegeDetails = this.previousEducation.get(
      "dualEnrollmentCollegeDetails"
    ) as FormArray;
    this.dualEnrollmentCollegeDetails.push(this.initializeDualEnrollment());
    for (let i = 0; i < this.dualEnrollmentCollegeDetailsValue.length; i++) { }
  }
  @HostListener('document:click', ['$event'])
  documentClick(event: MouseEvent) {
    this.previousEducation.get("collegeSearch").reset();
    this.previousEducation.get("highSchoolSearch").reset();
    this.collegeSearchChar = 0;
    this.collegesSearchChar = 0;
  }
  search(event) {
      if(event.keyCode != 37 && event.keyCode != 38 && event.keyCode != 39 && event.keyCode !=40){
        let query = event.target.value
        this.collegeSearchChar = query.length;
        if (this.collegeSearchChar > 1) {
          this.apiService.getHighSchoolList(query).subscribe((data: any[]) => {
            this.selectedSchool = data;
          });
        }
        let result = this.selectSchool(query);
        this.searchInput = query;
        this.selectedSchool = result;

        if (result.length == 0) {
          this.isSearchData = true;
          this.searchInput = query;
          this.selectedSchool = [];
          // this.selectedSchool.push(query);
        } else {
          this.isSearchData = false;
          this.selectedSchool = result;
        }
    }
  }

  selectSchool(query: string): string[] {
    let result: string[] = [];
    for (let a of this.schoolList) {
      result.push(a);
    }
    return result;
  }
  addSearchData() {
    this.selectedSchool.unshift(this.searchInput);
    this.schoolList.unshift(this.searchInput);
    this.previousEducation.get("highSchoolSearch").reset();
    this.selectedSchool = this.schoolList;
    this.isSearchData = false;
  }

  searchCollege(query) {
    if(query.keyCode != 37 && query.keyCode != 38 && query.keyCode != 39 && query.keyCode !=40){
      this.collegesSearchChar = query.target.value.length;
      let key = query.target.value
      if (this.collegesSearchChar > 1) {
        this.apiService.getCollegeList(key).subscribe((data: any[]) => {
          this.selectedCollege = data;
        });
      }
      let result = this.selectCollege(key);
      this.searchCollegeInput = key;
      this.selectedCollege = result;

      if (result.length == 0) {
        this.isCollegeSearch = true;
        this.searchCollegeInput = key;
        this.selectedCollege = [];
      } else {
        this.isCollegeSearch = false;
        this.selectedCollege = result;
      }
    }
  }
  selectCollege(query: string): string[] {
    let result: string[] = [];
    for (let a of this.highSchoolList) {
      result.push(a);
    }
    return result;
  }

  addCollegeData() {
    this.selectedCollege.unshift(this.searchCollegeInput);
    this.highSchoolList.unshift(this.searchCollegeInput);
    this.previousEducation.get("collegeSearch").reset();
    this.selectedCollege = this.highSchoolList;
    this.isCollegeSearch = false;
  }

  editHighSchoolDiploma() {
    this.editMode = true
    this.isPreviousEducationViewMode = false;
    this._connectionInterestService.viewMode[1]=false;
    this.updateFormStateOnEdit();
    this.registerService.updateData(this.previousEducationFormData);
  }

  // update form active state for other forms when editing current form
  updateFormStateOnEdit() {
    let fildData = this._connectionInterestService.getValueChange()
    fildData[1]=true
    this._connectionInterestService.fldShowValueChange(fildData);
  }

  // update form active state for other forms when sumiting current form
  updateFormStateOnSubmit() {
    let fildData = this._connectionInterestService.getValueChange()
    fildData[1]=false
    if(!this._connectionInterestService.viewMode[2])
    fildData[2]=true
    this._connectionInterestService.fldShowValueChange(fildData);
  }
  checkValidation(){
    let valid = false
    if(this.previousEducation.value.highSchoolDiploma){
      if(this.previousEducation.value.highSchoolDiploma.type == 'highSchool'){
        valid = this.previousEducation.value.highSchool && this.previousEducation.value.highSchoolGraduationYear &&  this.previousEducation.value.highSchoolGraduationMonth ? true : false
      }else if(this.previousEducation.value.highSchoolDiploma.type == 'homeSchool'){
        valid = this.previousEducation.value.homeSchool && this.previousEducation.value.homeSchoolGraduationYear &&  this.previousEducation.value.homeSchoolGraduationMonth ? true : false
      }else if(this.previousEducation.value.highSchoolDiploma.type == 'ged'){
        valid = this.previousEducation.value.GEDAwardState && this.previousEducation.value.gedGraduationYear &&  this.previousEducation.value.gedGraduationMonth ? true : false
      }
    }
    if(this.previousEducation.value.finalCollegeList == 'yes' && this.previousEducation.value.graduateInstitution == 'yes'){
      valid = this.previousEducation.value.college && this.previousEducation.value.collegeGraduationMonth && this.previousEducation.value.collegeGraduationYear ? true : false
    }   
    return valid
  }
}
